import React, { useState, useEffect } from "react";
import s from './styles.module.scss';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import Button from '../../../components/views/buttonLotaspeak'
import API from 'utils/Api';
import Thread from '../../../components/threads/thread';
import AuthPopup from '../../../pages/auth/modal';
import WrongLink from './wrongLink'



const JoinNotAuthorized = inject("ProfileStore")(observer((props) => {
    const [isLoading, setLoading] = useState(false)
    const [thread, setThread] = useState();
    const [isAuthPopupVisible, setAuthPopupVisible] = useState(false);
    const [isBadUrlError, setBadUrlError] = useState(false);


    useEffect(() => {
        GetThread();
    }, []);

    const handleLoginClick = () => {
        // navigationActiveButtonEvent('login')
        setAuthPopupVisible(!isAuthPopupVisible)
    }


    const GetThread = (event) => {
        setLoading(true);
        API.get(
            `thread/detail/${props.roomId}`
        ).then(res => {
            debugger
            setLoading(false);
            if (res.data && res.data.thread) {
                setThread(res.data.thread)
            } else {
                setBadUrlError(true)
            }
        }).catch(error => {
            debugger
            setLoading(false);
        });
    };

    const routerToChat = () => {
        if (props.loggedIn) props.loggedIn()
    }

    return (
        <div className={s.body}>
            {!isBadUrlError ?
                <>
                    {thread && <div className={s.join}>
                        <Button title='Login' onClick={handleLoginClick} />
                    </div>}

                    <div className={s.titleJoin}>You need to login in order to join</div>
                    <div className={s.titleJoin}>To the thread</div>

                    <div className={s.thread}>
                        {thread && <Thread thread={thread} />}
                    </div>

                    {isAuthPopupVisible && <AuthPopup route={() => routerToChat()} />}
                </>
                : <WrongLink />
            }
        </div >
    )
}));

export default withRouter(JoinNotAuthorized);
