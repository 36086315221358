
import React from 'react'
import s from "./styles.module.scss"
import { withRouter } from 'react-router-dom'
import { pageEvent } from 'utils/analitycs'

const About = () => {

    pageEvent('about')

    return (
        <div className={s.body}>
            <div className={s.title}>What is Linguaspeak?</div>
            <div className={s.text}>Linguaspeak - platform where you can find people to practice foreign languages.</div>

            <div className={s.title} styles={{ margingTop: '30px' }}>How to use Linguaspeak?</div>

            <ul>
                <li className={s.text}>Create your profile</li>
                <li >Find a chat room for your target language and topic or create your own room with custom settings and rules</li>
                <li className={s.text}>Talk to natives, be polite, and rate other users</li>
            </ul>

            <div className={s.title} styles={{ margingTop: '30px' }}>How to find partners on linguaspeak?</div>

            <div className={s.text}>All information about finding speaking partners you can read in the article.</div>
            <p><strong><a href="/en/blog/how-to-find-partners-on-linguaspeak" className={s.text}>Read the article</a></strong></p>

       
        </div>
    );
};

export default withRouter(About);
