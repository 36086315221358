export function getResponseError(error) {
    let message = '';
    try {
        if (error.response.data.data.message) {
            if (error.response.data.data.message.error) {
                message = error.response.data.data.message.error
            } else {
                message = error.response.data.data.message
            }
        } else if (error.response.data.data.errors.length > 0) {
            for (let i = 0; i < error.response.data.data.errors.length; i++) {
                message += `${getValue(error.response.data.data.errors[i])} \n`;
            }
        } else {
            message = error.message
        }
    } catch (e) {
        message = 'Something went wrong =('
    }

    return message;
}

function getValue(obj) {
    return Object.values(obj)[0];
}