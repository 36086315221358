import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styles from "./styles.module.scss"
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { joinToThreadEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji'
import SubscribeDialogTelegram from './subscribeDialogTelegram'

const SubscribeTelegram = inject("ProfileStore")(observer((props) => {

    const [showDialog, setShowDialog] = useState(false)

    const { thread } = props
    const { language } = thread
    const { _id } = language

    const onModalClose = () => {
        setShowDialog(false);
    }

    const View = () => {
        return <>
            <div
                className={styles.textButton}
                onClick={() => {
                    joinToThreadEvent('click subscribe telegram')
                    setShowDialog(true)
                }}>Subscribe</div>

            {showDialog && <SubscribeDialogTelegram onModalClose={onModalClose} show={showDialog} languageId={_id} />}
        </>
    }

    if (_id === englishLanguageId || _id === spanishLanguageId) {
        return (
            View()
        )
    } else {
        return (
            <></>
        )
    }
}));

export default withRouter(SubscribeTelegram);


export const englishLanguageId = '5d02400c1c9d440000366291'
export const spanishLanguageId = '5d0240731c9d440000366292'