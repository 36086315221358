import React from "react";
import s from './styles.module.scss';
import { customPageEvent } from 'utils/analitycs';
import Emoji from 'utils/emoji';

const FindPartnersArticle = () => {
    customPageEvent("article", "How to find partner on Linguaspeak?")

    return (
        <div className={s.body}>
            <div className={s.title}>How to find partners for speaking on Linguaspeak?</div>


            <div className={s.blockText}>

                <div className={s.space} />
                <p>There are two options how to find people on linguaspeak.</p>
                <p><strong>1. On the main page, there is a list of rooms, you can find an appropriate room and just join it.</strong></p>

                <img className={s.bannerInMiddle} src="https://storage.yandexcloud.net/lotaspeak-images/project/articles/img_article_how_to_find_partners_feed.png" alt="feed of linguaspeak" />

                <p><strong>Each room has its own characteristics:</strong></p>

                <p>
                    <ul>
                        <li><span>Target language <Emoji symbol='🙊' /></span></li>
                        <li><span>Recommended levels of language <Emoji symbol='👉' /> (<strong><a href="https://www.linguaspeak.com/info/levels" target="_blank">System of levels</a></strong>)</span></li>
                        <li><span>The theme discussed in the room <Emoji symbol='💬' /></span></li>
                        <li><span>Joined users in the room <Emoji symbol='😊' /></span></li>
                        <li><span>Free places, if the room is full you can't join it <Emoji symbol='🪑' /></span></li>
                    </ul>
                </p>

                <p>Based on these characteristics, you can choose the room you need and just join it.</p>


            </div>

            <div className={s.blockText}>

                <div className={s.space} />
                <p><strong>2. You can create your own room and joind it.</strong></p>

                <p>In order to create new room you must click on the button - '<strong>Create new room</strong>' which is located above all rooms.</p>

                <img className={s.bannerInMiddle} src="https://storage.yandexcloud.net/lotaspeak-images/project/articles/img_article_how_to_find_partners_create_room.png" alt="Create new room on linguaspeak" />

                <p>Here you can set up your own parameters and create the new room.</p>

                <p>After creation, you can wait for users who join to your room.</p>

            </div>



            <div className={s.blockText}>

                <div className={s.space} />

                <p><strong>Thanks for the reading, Have a good time! <Emoji symbol='😊' /></strong></p>

                <div className={s.space} />
            </div>
        </div>
    )
}

export default FindPartnersArticle;
