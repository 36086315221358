import React from "react";
import s from './styles.module.scss';
import { routeFeed } from 'utils/routes'
import { withRouter } from 'react-router';

const WronkLink = (props) => {

    return (
        <>
            <img className={s.imageWrong} src="/ic_sorry_wrong_link.svg" alt='Wrong invitation link' />
            <div className={s.titleJoin}>You have the wrong link, or the thread no longer exists</div>
            <div className={s.titleComeBack} onClick={() => routeFeed(props)}>Come back to the main page</div>
        </>
    )
};

export default withRouter(WronkLink);
