import React from 'react'
import { withRouter } from 'react-router-dom'
import s from "./styles.module.scss"

const NotFoundPage = (props) => {

    const routeMain = () => {
        const { history } = props;
        if (history) history.push(`/`);
    }

    return <div className={s.body}>
        <p className={s.title}>
            <a onClick={routeMain}>Go to Home </a>
        </p>
        <img className={s.img} src="https://storage.yandexcloud.net/lotaspeak-images/project/img_error_not_found.png" />
    </div>;
}

export default withRouter(NotFoundPage)