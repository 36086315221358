import React, { useState } from 'react';
import API from 'utils/Api';
import { getResponseError } from '../validation';
import { Button, FormGroup, FormControl, FormLabel, Form, Spinner } from "react-bootstrap";
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';



const NewPassword = (props) => {
    const [errors, setErrors] = useState(new Map());
    const [isLoadingState, setLoadingState] = useState(false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const updatePasswordPost = (event) => {
        event.preventDefault();
        setLoadingState(true);

        setErrors(new Map());

        API.post(
            'auth/newpassword',
            {
                token: props.token,
                password: password,
                password2: password2,
            }
        ).then(res => {
            setLoadingState(false);
            if (res.data.success) {
                toast('🎉 Hooray, You successfully updated password');
                props.handleClose();
            } else { 
                // FIXME: handle this error
                console.log(res);
            }
        }).catch(error => {
            const errors = getResponseError(error);
            setErrors(errors);
            setLoadingState(false);
        });
    }

    const validateForm = () => {
        return password.length > 0 && password2.length > 0;
    }

    const UpdateButton = (isLoading) => {
        if (!isLoading) {
            return <Button block bsSize="large" className={styles.submit} disabled={!validateForm()} type="submit">
                Update</Button>
        } else {

            return <Button block bsSize="large" className={styles.submit} disabled type="submit">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
        }
    }

    return (
        <div className={styles.body}>
            <div className={styles.block}>
                <form onSubmit={updatePasswordPost}>
                    <FormGroup controlId="password" bsSize="large">
                        <FormLabel>Password</FormLabel>
                        <FormControl
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            maxLength="16"
                            isInvalid={!!errors.get('password')}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('password')}
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup controlId="password2" bsSize="large">
                        <FormLabel>Repeat password</FormLabel>
                        <FormControl
                            value={password2}
                            onChange={e => setPassword2(e.target.value)}
                            type="password"
                            maxLength="16"
                            isInvalid={!!errors.get('password2')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('password2')}
                        </Form.Control.Feedback>
                    </FormGroup>
                    {UpdateButton(isLoadingState)}
                </form>
            </div>
        </div>
    );
}

export default withRouter(NewPassword);