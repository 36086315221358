import ReactGA from 'react-ga'
// import ym from 'react-yandex-metrika'


export const pageEvent = (event) => {
    ReactGA.pageview(event);
    // ym('page', event)
}

export const modalEvent = (event) => {
    ReactGA.modalview(event);
}

export const linkOpenEvent = (event) => {
    // ym("route Link", event)

    ReactGA.event({
        category: "route Link",
        action: event,
    });
}

// Events on my profile page
export const customPageEvent = (category, event) => {
    // ym(category, event)
    ReactGA.event({
        category: category,
        action: event,
    });
}

// Events on my profile page
export const myProfilePageEvent = (event) => {
    // ym("my profile page", event)

    ReactGA.event({
        category: "my profile page",
        action: event,
    });
}

// Events on my footer block
export const footerEvents = (event) => {
    // ym("footer", event)

    ReactGA.event({
        category: "footer",
        action: event,
    });
}

// Events clicked buttons on navigation bar
export const navigationActiveButtonEvent = (event) => {
    // ym("navigation bar", event)

    ReactGA.event({
        category: "navigation bar",
        action: event,
    });
}

// export const threadEvent = (event, language, level) => {
//     ReactGA.event({
//         category: "Thread",
//         action: event,
//     });
// }

export const joinToThreadEvent = (title) => {
    // ym("join thread", title)

    ReactGA.event({
        category: "join thread",
        action: title,
    });
}

export const createNewThreadEvent = (title) => {
    // ym("create Thread", title)

    ReactGA.event({
        category: "create Thread",
        action: title,
    });
}
