import React from "react";
import { Button, FormGroup, FormControl, FormLabel, Spinner, Form } from "react-bootstrap";
import styles from "./styles.module.scss";
import API from 'utils/Api';
import PropTypes from 'prop-types';
import { getResponseError } from '../validation';
import { AUTH_STATE } from '../../../pages/auth/modal'
import Facebook from '../social/facebook'
import { modalEvent } from 'utils/analitycs'


export default class Register extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            password2: '',
            username: '',
            onLoginListener: props.onLoginListener,
            isLoadingState: false,
            errors: new Map()
        }

        modalEvent('Register')
    }

    validateForm = () => {
        return this.state.email.length > 0
            && this.state.password.length > 0
            && this.state.password2.length > 0
            && this.state.username.length;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setLoadingState(true);

        const dataBody = {
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            username: this.state.username
        };

        API.post(
            'auth/register',
            dataBody,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            this.setLoadingState();
            if (res.data) {
                this.props.onRegistered(res.data);
            } else {
                // FIXME: handle this error
                console.log(res);
            }
        }).catch(error => {
            const errors = getResponseError(error)
            this.setState({ errors: errors, isLoadingState: false });
        });
    }

    setLoadingState = (state = false) => {
        this.setState({
            isLoadingState: state
        });
    }

    renderButtonState = (isLoading) => {
        if (!isLoading) {
            return <Button block bsSize="large" className={styles.buttonRegister} disabled={!this.validateForm()} type="submit">
                Register</Button>
        } else {

            return <Button block bsSize="large" className={styles.buttonRegister} disabled type="submit">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
        }
    }

    render() {
        const { errors } = this.state;

        return (
            <div className={styles.body}>
                <Facebook onLogined={this.props.onRegistered} needInputEmail={this.props.needInputEmail}/>
                <div className={styles.title}>Or Sign up email</div>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="username" bsSize="large">
                        <FormLabel>Username</FormLabel>
                        <FormControl
                            autoFocus
                            type="username"
                            maxLength="50"
                            value={this.state.username}
                            isInvalid={!!errors.get('username')}
                            onChange={e => this.setState({ username: e.target.value })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('username')}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            type="email"
                            maxLength="50"
                            value={this.state.email}
                            onChange={e => this.setState({ email: e.target.value })}
                            isInvalid={!!errors.get('email')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('email')}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <FormLabel>Password</FormLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={e => this.setState({ password: e.target.value })}
                            type="password"
                            maxLength="50"
                            isInvalid={!!errors.get('password')}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('password')}
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup controlId="password2" bsSize="large">
                        <FormLabel>Repeat password</FormLabel>
                        <FormControl
                            value={this.state.password2}
                            onChange={e => this.setState({ password2: e.target.value })}
                            type="password"
                            maxLength="50"
                            isInvalid={!!errors.get('password2')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('password2')}
                        </Form.Control.Feedback>
                    </FormGroup>

                    {this.renderButtonState(this.state.isLoadingState)}

                </form>
                <div className={styles.signup}>Have an account?<span
                    className={styles.signUpTitle}
                    onClick={() => this.props.onChangeAuthState(AUTH_STATE.LOGIN)}>
                    Log in</span>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    onRegistered: PropTypes.func,
    onChangeAuthState: PropTypes.func
};