import { Modal } from 'react-bootstrap'
import React, { useState } from "react";
import Register from '../../components/auth/register/index';
import Login from '../../components/auth/login/index';
import RestorePassword from '../../components/auth/recoverPassword';
import NewPassword from '../../components/auth/recoverPassword/newPassword';
import InputEmail from '../../components/auth/inputEmail';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';


export const AUTH_STATE = {
  LOGIN: { value: 0, title: 'Login' },  /// login page
  REGISTER: { value: 1, title: 'Register' }, // register page
  RESTORE_PASSWORDD: { value: 2, title: 'Restore Password' }, // page with code
  NEW_PASSWORD: { value: 3, title: 'Update Password', token: '' }, // page with updating passwords
  INPUT_EMAIL: { value: 4, title: 'Input your email', user: '' }, // page with updating passwords
};

const AuthModalPage = inject("ProfileStore")(observer((props) => {
  const [show, setShow] = useState(true);
  const [authState, setAuthState] = useState(AUTH_STATE.LOGIN);

  const handleClose = () => setShow(false);

  const onRegistered = (authData) => {
    const { user: userObj, token } = authData;

    setShow(false);

    toast('🎉 Hooray, You are a linguaspeaker');

    props.ProfileStore.setProfile(userObj);
    props.ProfileStore.setAccessToken(token);
    localStorage.setItem('comgins_soon_page', true)

    debugger
    if (!userObj.isRegisterCompleted) {
      goTo();
    } else {
      routeParam()
    }
  };

  const goTo = () => {
    const { history } = props;
    if (history) history.push('/auth/update');
  };

  const needInputEmail = (user) => {
    const state = AUTH_STATE.INPUT_EMAIL
    state.user = user
    setAuthState(state)
  }

  const onLogined = (authData) => {
    const { user, token } = authData;

    setShow(false);

    toast('🎉 Hooray, You are a linguauser');

    props.ProfileStore.setProfile(user);
    props.ProfileStore.setAccessToken(token);
    localStorage.setItem('comgins_soon_page', true)

    if (!user.isRegisterCompleted) { // If detailed information now filled go to update info
      goTo();
    } else {
      routeParam()
    }
  };

  const routeParam = () => {
    const { route } = props
    if (route) route()
  }

  const onChangeAuthState = (authState) => {
    setAuthState(authState);
  };

  const getCurrentViewState = () => {
    let authView;
    switch (authState.value) {
      case AUTH_STATE.LOGIN.value:
        authView = <Login
          onChangeAuthState={onChangeAuthState}
          onLogined={onLogined}
          needInputEmail={needInputEmail}
        />
        break;

      case AUTH_STATE.RESTORE_PASSWORDD.value:
        authView = <RestorePassword
          onChangeAuthState={onChangeAuthState}
        />
        break;

      case AUTH_STATE.NEW_PASSWORD.value:
        authView = <NewPassword
          onChangeAuthState={onChangeAuthState}
          token={authState.token}
          handleClose={handleClose}
        />
        break;

      case AUTH_STATE.INPUT_EMAIL.value:
        authView = <InputEmail
          onChangeAuthState={onChangeAuthState}
          user={authState.user}
          handleClose={handleClose}
          onLogined={onLogined}
        />
        break;

      default:
        authView = <Register
          onChangeAuthState={onChangeAuthState}
          onRegistered={onRegistered}
          needInputEmail={needInputEmail}
        />
    }
    return authView;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold' }}>{authState.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {getCurrentViewState()}
        </Modal.Body>
      </Modal>
    </>
  );
}));

export default withRouter(AuthModalPage);