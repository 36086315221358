import React from 'react'
import styles from './styles.module.scss'
import Members from '../../threads/members/unlimited'
import { withRouter } from 'react-router-dom'
import SubscribeButton from '../subscribeButton'
import Levels from '../../views/levels'
import Emoji from 'utils/emoji'
import JoinButton from '../../threads/button'

const Club = (props) => {
    const { club, key, joinEvent } = props
    const { title, language, level, members, startClubTime, _id, author } = club

    const StartDate = () => {
        const d = new Date(startClubTime)

        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]

        const monthName = months[d.getMonth()]
        const year = d.getFullYear()
        const date = d.getDate()
        const hours = d.getHours()
        let minutes = d.getMinutes()

        const timeFormatted = `${getValue(hours)}:${getValue(minutes)}`
        const dateFormatted = `${date} ${monthName} ${year}`

        if (isClubStarted()) {
            return <div className={styles.date}>
                <Emoji symbol='🎉' />
                <span>&#160;</span>
                <div className={styles.title}>The club has started!</div>
            </div>
        } else {
            return <div className={styles.date}>
                <Emoji symbol='🏁' />
                <span>&#160;</span>
                <div className={styles.title}>Club will start</div>
                <div className={styles.titleTime}>at {timeFormatted}</div>
                <div className={styles.titleDate}>{dateFormatted}</div>
            </div>
        }
    }

    const getValue = (value) => {
        return (value < 10 ? '0' : '') + value
    }

    const isClubStarted = () => {
        return new Date(startClubTime) < new Date()
    }

    const Button = () => {
        if (isClubStarted()) {
            return <div>
                <div className={styles.titleClubStarted}>The club has started, You can join!</div>
                <JoinButton thread={club} />
            </div>
        } else {
            return <SubscribeButton club={club} joinEvent={joinEvent} />
        }
    }

    const RegisteredParticipantsTitle = () => {
        let membersCount = 0
        if (members) {
            membersCount = members.length
        }
        return <div className={styles.titleClubStarted}><Emoji symbol='✔️' /> {membersCount} registered participants!</div>
    }

    const routeToClubInfo = () => {
        const { history } = props;
        if (history) history.push(`/speakingclub/${_id}`);
    }

    return (
        <div className={styles.body} key={key} onClick={routeToClubInfo}>
            <div className={styles.header}>
                <div>
                    <div className={styles.title}><Emoji symbol={language.flag} /> {language.title}</div>

                    <Levels
                        levelsThread={level}
                        threadLanguage={language}
                    />

                    {title && <div className={styles.themeDiv}><span className={styles.themeTitle}><Emoji symbol='💬' /> Theme: </span>"{title}"</div>}
                </div>
                <StartDate />
            </div>

            {/* TODO: try load these members separatly in every thread */}

            {RegisteredParticipantsTitle()}
            <Members members={members} author={author}/>

            <div className={styles.footer}>
                {Button()}
            </div>
        </div>
    );
}

export default withRouter(Club)