import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "mobx-react";
import mainStore from "./stores/mainStore";

const stores = {
    mainStore,
    ProfileStore: mainStore.ProfileStore
};

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>, document.getElementById('root')
);

const APP = () => {
    return <Provider {...stores}>
        <App />
    </Provider>
}

// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//     ReactDOM.hydrate(<APP />, rootElement);
// } else {
//     ReactDOM.render(<APP />, rootElement);
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
