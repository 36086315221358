import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import API from 'utils/Api';
import { buildName } from 'utils/user';
import { Spinner } from "react-bootstrap";
import UserProfile from '../../../domain/userProfile';
import { withRouter } from 'react-router-dom';
import ProfileHeader from './header'
import Block from './block'
import Languages from './languages'
import StarRatings from 'react-star-ratings';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { pageEvent } from 'utils/analitycs'

const ProfilePage = (props) => {

    pageEvent('profile page')

    const [user, setUser] = useState({});
    const [profile, setProfile] = useState(UserProfile.getUserProfile());
    const [isLoadingState, setLoadingState] = useState(false);
    const [name, setName] = useState('');
    const [authorized, setAuthorized] = useState();

    const params = {
        username: props.match.params.username,
        id: props.match.params.id
    }

    useEffect(() => {
        let ignore = false;

        setLoadingState(true);

        const dataBody = {
            userId: params.id
        };

        API.post(
            `user/${params.username}`,
            dataBody,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            setLoadingState(false)
            if (res.data) {
                if (!ignore) setUser(res.data.user);
            } else {
                // FIXME: handle this error
                console.log(res);
            }

        }).catch(error => {
            setLoadingState(false)
        });

        return () => { ignore = true; }
    }, []);

    useEffect(() => {
        setName(buildName(user));
    }, [user]);

    const isAuthorized = (user, profile, authorized) => {
        if (typeof authorized === 'undefined') {
            setAuthorized(user && profile && profile._id === user._id);
            return true;
        } else {
            return authorized;
        }
    }

    const ProfileBody = () => {
        const mainContent = <>
            <Body />
        </>;

        const LanguagesContent = <Languages languages={user.languages} />

        const RatingsContent = <Ratings rating={user.rating} />

        return <>
            <ProfileHeader user={user} />
            <Block content={mainContent} title="Main" />

            {user.rating !== undefined && user.rating.rating !== undefined && user.rating.rating > 0  &&
                <Block content={RatingsContent} title="Rating" />
            }
            {user.languages && <Block content={LanguagesContent} title="Languages" />}
        </>
    }

    const UserBody = () => {
        const mainContent = <>
            <Body />
        </>;

        const LanguagesContent = <Languages languages={user.languages} />

        const RatingsContent = <Ratings rating={user.rating} />

        return <>
            <Block content={mainContent} title="Main" />

            {user.rating !== undefined && user.rating.rating !== undefined && user.rating.rating > 0  &&
                <Block content={RatingsContent} title="Rating" />
            }
            {user.languages && <Block content={LanguagesContent} title="Languages" />}
        </>

    }

    const StarRating = (rating, title) => {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{title} - {rating}</Tooltip>}>
            <div className={styles.blockRating}>
                <div className={styles.titleRating}>{title}</div>
                <StarRatings
                    data-tip='tooltip'
                    starRatedColor="#ff5a5f"
                    rating={rating}
                    starSpacing='1px'
                    numberOfStars={5}
                    starDimension='30px'
                />
            </div>
        </OverlayTrigger >
    }

    const Ratings = (props) => {
        const { rating } = props;

        return <div className={styles.ratingBody} >
            {rating && rating.rating > 0 && (
                <>
                    <>
                        {StarRating(rating.rating, 'Overall')}
                    </>

                    <>
                        {StarRating(rating.speakingQuality, 'Speaking')}
                    </>

                    <>
                        {StarRating(rating.connectionQuality, 'Connection')}
                    </>
                </>
            )}
        </div >
    }

    const Body = () => {
        return <div className={styles.mainBody}>
            <img className={styles.image} src={user.image && user.image.url ? user.image.url : '/anonim.png'} alt='icon' />

            <div className={styles.bodyRight}>
                <div className={styles.name}>{name}</div>
                {user.bio && user.bio !== 'undefined'
                    && <div className={styles.bioTitle}><span className={styles.bio}>Bio: </span>{user.bio}</div>
                }
            </div>
        </div>
    }

    return (
        <div className={styles.body}>
            {Object.keys(user).length !== 0 ? (
                isAuthorized(user, profile, authorized) ? (<ProfileBody />) : (<UserBody />)
            ) : (
                    isLoadingState &&
                    <Spinner className={styles.loader}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                )
            }
        </div>
    );
}

export default withRouter(ProfilePage);