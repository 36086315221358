import React from 'react';
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { getRefreshedUrl } from 'utils/user'

class User extends React.PureComponent {
    constructor(props) {
        super(props);

        const sizeImage = props.sizeImage ? props.sizeImage : 80

        this.state = {
            sizeImage: sizeImage,
            user: props.user,
            isHoverUser: false,
            isHost: props.isHost
        }
    }

    onClick = (e) => {
        e.stopPropagation()
        if (this.props.onClickUser) {
            this.props.onClickUser(this.state.user);
        } else {
            const { history } = this.props;
            if (history) history.push(`/user/${this.props.user.username}/${this.props.user._id}`);
        }
    }

    StarRating = () => {
        let rating = 0;
        if (this.state.user.rating && this.state.user.rating.rating) {
            rating = this.state.user.rating.rating;
        }
        return <StarRatings
            starRatedColor="#ff5a5f"
            rating={rating}
            starSpacing='1px'
            numberOfStars={5}
            starDimension='15px'
        />
    }

    LanguageAbilityTitle = (languageAbilityTitle) => {
        return <div style={{ fontSize: "11px" }}>{languageAbilityTitle}</div>
    }

    Image = ({ user, sizeImage }) => {
        const url = user.image && user.image.url ? getRefreshedUrl(user.image.url) : '/anonim.png';

        if (this.state.isHost) {
            return <img
                className={styles.imageHost}
                src={url}
                width={sizeImage}
                height={sizeImage}
                alt='ava' />
        } else {
            return <img
                className={styles.image}
                src={url}
                width={sizeImage}
                height={sizeImage}
                alt='ava' />
        }
    }

    render() {
        const { user } = this.state;
        const name = user.username;

        return (
            <div className={styles.body}
                id='user-block'
                onClick={this.onClick}
                onMouseEnter={() => this.setState({ isHoverUser: true })}
                onMouseLeave={() => this.setState({ isHoverUser: false })}
            >
                {user &&
                    <div >
                        {this.Image(this.state)}
                        {this.StarRating()}
                        <div className={styles.name} >{name}</div>
                        {this.LanguageAbilityTitle(user.languageAbilityTitle)}
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(User);

// // make the short info under user's avatar like in Reddit 🇷🇺(N)|🇺🇸(B2)|🇩🇪(Starter)
// export const buildShortTitleLaguagesLevels = (user) => {
//     let title

//     debugger
//     if (user) {

//     }

//     return title
// }