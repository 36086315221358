/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import styles from "./styles.module.scss";
import Select from 'react-select';
import Emoji from 'utils/emoji';
import { withRouter } from 'react-router-dom'

const LanguagesSelector = (props) => {

    const { levels, languages, userLanguages, onSelectedItemsListener } = props;

    const initSelectedLanguages = (userLanguages) => {
        let items = [];

        const length = userLanguages && userLanguages.length > 0 ? userLanguages.length : 3;

        for (let i = 0; i < length; i++) {
            let language = {};
            let level = {};

            if (userLanguages && userLanguages.length > 0 && i < userLanguages.length) {
                language = userLanguages[i].language;
                level = userLanguages[i].level;
            }
            items.push({ level: level, language: language })
        }
        return items;
    }

    const [selectedLanguages, setSelectedLanguages] = useState(initSelectedLanguages(userLanguages));

    const handleChangeLevel = (index, option) => {
        const items = [...selectedLanguages]
        items[index].level = option.value;
        setSelectedLanguages(items);
        onSelectedItemsListener(selectedLanguages);
    }

    const handleChangeLanguages = (index, option) => {
        const items = [...selectedLanguages]
        items[index].language = option.value;
        setSelectedLanguages(items);
        onSelectedItemsListener(selectedLanguages);
    }

    const Language = (index, language) => {
        if (languages) {
            const items = buildSelectArray(languages)

            if (language) {
                return <Select
                    value={items.filter(item => item.value === language)}
                    className={styles.language}
                    options={items}
                    onChange={handleChangeLanguages.bind(this, index)}
                    placeholder='Language' />
            } else {
                return <Select
                    className={styles.language}
                    options={items}
                    onChange={handleChangeLanguages.bind(this, index)}
                    placeholder='Language' />
            }
        }
    }

    const Level = (index, level) => {
        if (levels && levels.length) {
            const items = buildSelectArray(levels)
            if (level) {
                return <Select
                    value={items.filter(item => item.value === level)}
                    className={styles.level}
                    options={items}
                    onChange={handleChangeLevel.bind(this, index)}
                    placeholder='Level' />
            } else {
                return <Select
                    className={styles.level}
                    options={items}
                    onChange={handleChangeLevel.bind(this, index)}
                    placeholder='Level' />
            }
        }
    }

    const routeInfoLevels = () => {
        const { history } = props;
        if (history) history.push(`/info/levels`);
    }

    const Languages = (selectedLanguages) => {
        let learningLanguagesViews = [];

        for (let i = 0; i < selectedLanguages.length; i++) {
            const lanugageSelected = Object.keys(selectedLanguages[i].language).length ? selectedLanguages[i].language : null;
            const levelSelected = Object.keys(selectedLanguages[i].level) ? selectedLanguages[i].level : null;

            const view = <div className={styles.lanlev} key={i}>
                {Language(i, lanugageSelected)}
                {Level(i, levelSelected)}
                <div className={styles.remove} onClick={onClickRemove.bind(this, i)}>
                    <img
                        alt=""
                        src="/ic_delete_line.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </div>
            </div>

            learningLanguagesViews.push(view);
        }

        return <div>
            <div className={styles.lanlevTitle}>Languages ​​I speak and learn
                <img onClick={routeInfoLevels}
                    alt=""
                    src="/ic_question_buble.svg"
                    width="30"
                    height="30"
                    className={styles.questionIcon}
                /></div>

            <a target='_blank' className={styles.titleExplanation} href='/info/levels'>See the table for an explanation of the levels</a>
            {learningLanguagesViews}
        </div>
    }

    const onClickRemove = (index) => {
        let items = [...selectedLanguages];
        items.splice(index, 1);

        setSelectedLanguages(items);
        onSelectedItemsListener(items);
    }

    const onPlusOneRowLanguageClick = () => {
        let items = [...selectedLanguages];
        items.push({ level: {}, language: {} })
        setSelectedLanguages(items);
        onSelectedItemsListener(items);
    }

    return (
        <div className={styles.body}>
            {Languages(selectedLanguages)}

            <div className={styles.addBtn} onClick={onPlusOneRowLanguageClick} >
                <img
                    alt=""
                    src="/ic_add_line.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />
            </div>
        </div>
    );
}

export default withRouter(LanguagesSelector);

export const buildSelectArray = (items) => {
    let selectItems = [];
    if (items) {
        items.map(item => {
            if (item.sort !== -1) {
                let label = item.title;
                if (item.flag) {
                    label = <><Emoji symbol={item.flag} /> {item.title}</>
                }
                selectItems.push({ value: item._id, label: label })
            }
        })
    }
    return selectItems;
}
