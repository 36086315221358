import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from "./styles.module.scss";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import API from 'utils/Api';
import { USER_NUMBER_ACTIVE_RATINGS } from 'utils/variables';
import { Spinner } from "react-bootstrap";
import { joinToThreadEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';

const Button = inject("ProfileStore")(observer((props) => {
    const [isLoadingState, setLoadingState] = useState(false);

    const { thread } = props;
    const { members, numberSeats, roomId, minRatingOfUser, author } = thread;

    const goTo = () => {
        function openNewTab(url) {
            const win = window.open(url, '_blank');
            if (win != null) {
                win.focus();
            }
        }
        openNewTab(`/chat/${roomId}`);
    }

    const joinToThread = () => {
        buildBundler(thread, props.ProfileStore.params.profile);
        goTo();
        if (props.joinEvent) props.joinEvent()
    }

    const checkRatingUser = ({ email, _id }) => {
        let ignore = false;

        setLoadingState(true);

        API.get(
            `/user/rating/${email}`,
        ).then(res => {
            setLoadingState(false);
            if (!ignore) {
                if (res.data && res.data.rating) {
                    const isValidUserRating = (res.data.rating.numberRatings > USER_NUMBER_ACTIVE_RATINGS
                        && res.data.rating.rating > minRatingOfUser)
                        || author === _id
                        || res.data.rating.numberRatings < USER_NUMBER_ACTIVE_RATINGS;

                    if (res.data.rating.numberRatings === 0 || isValidUserRating) {
                        joinToThread();
                    } else {
                        toast.warning(`🙁 Your rating - (${res.data.rating.rating}) is lower than allowed in the room - (${thread.minRatingOfUser}).`, { autoClose: 5000 });
                    }
                } else {
                    joinToThread();
                }
            }
        }).catch(error => {
            setLoadingState(false);
            toast.error('🙁 Ups, something gets wrong!');
        });

        return () => { ignore = true; }
    };

    const buildBundler = ({ roomId, title }, { username, email }) => {
        localStorage.setItem(roomId, JSON.stringify({
            roomId: roomId,
            username: username,
            email: email,
            title: title
        }));
    };

    const Join = () => {
        if (props.ProfileStore.params.profile && thread && roomId) {
            if (!isLoadingState) {
                return <div
                    className={styles.activeButton}
                    onClick={(e) => {
                        e.stopPropagation()
                        joinToThreadEvent('click join thread');
                        checkRatingUser(props.ProfileStore.params.profile);
                    }}
                >Join</div>
            } else {
                return <div className={styles.activeButton} disabled >
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            }
        } else return <div className={styles.activeButton} onClick={onClickConnect}>Join</div>
    };

    const onClickConnect = () => {
        if (props.ProfileStore.params.profile) {
            if (roomId == undefined || roomId == null || roomId === '') {
                toast.error('🤝 there is no room id', { autoClose: 2000 });
                // FIXME: handle Error
            }
        } else {
            joinToThreadEvent('not authorized');
            toast.warn('🤝 You need a login, in order to join a thread', { autoClose: 2000 });
        }
    }

    if (members.length < numberSeats || numberSeats === 'Unlimited') {
        return (
            Join()
        );
    } else {
        return (
            <div className={styles.nonActiveButton}><Emoji symbol='😢'/> Full</div>
        );
    }
}));

export default withRouter(Button);