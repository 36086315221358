import React, { useEffect, useState } from "react"
import DatePickerLibrary from "react-datepicker"
import styles from "./styles.module.scss"

import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ onSelect }) => {
    const [startDate, setStartDate] = useState(new Date(new Date().getTime() + 86400000))

    useEffect(() => {
        onSelect(startDate)
    }, [startDate]);

    const Time = () => {
        
        let hours = startDate.getHours()
        let minutes = startDate.getMinutes()

        debugger
        if (minutes.toString().length == 1) {
            minutes = `0${minutes}`
        }

        if (hours.toString().length == 1) {
            hours = `0${hours}`
        }

        const timeFormatted = `${hours}:${minutes}`

        return <div className={styles.time}>at {timeFormatted}</div>
    }

    return <div className={styles.body}>
        <div className={styles.themeTitle}>Start date</div>
        <div className={styles.line}>
            <DatePickerLibrary
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
            />
            <Time />
        </div>
    </div>
}

export default DatePicker