import React from "react";
import styles from "./styles.module.scss";
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { myProfilePageEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';

const ProfileHeader = inject("ProfileStore")(observer ((props) => {
    const { user } = props;

    const Edit = ({user}) => {
        const onClick = () => {
            const { history } = props;
            if (history) history.push('/auth/update');  
            myProfilePageEvent('edit')
        };
        return <button className={styles.edit} onClick={onClick}><Emoji symbol='⚙️'/> Edit</button>
    }
    
    const Exit = () => {
        const onClick = () => {
            props.ProfileStore.clearProfile();
            goTo();
            myProfilePageEvent('exit')
        }
        return <button className={styles.edit} onClick={onClick}><Emoji symbol='😢'/> Log out</button>
    }

    const goTo = () => {
        const { history } = props;
        if (history) history.push('/');
    }

    return (
        <div className={styles.body}>
            <div className={styles.title}><Emoji symbol='🙂'/> My Profile</div>
            <div className={styles.block}>
                <Edit user={user}/>
                <Exit />
            </div>
        </div>
    );
}))

export default withRouter(ProfileHeader);

