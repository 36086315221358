/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import s from './styles.module.scss';
import { withRouter } from 'react-router';
// import { pageEvent } from 'utils/analitycs';
import { copyToClipboard } from 'utils/utils';

const CopyLink = (props) => {
    const [copyState, setCopyState] = useState('Copy');

    const link = `https://www.linguaspeak.com/chat/${props.roomId}`

    const onClick = () => {
        copyToClipboard(link)
        setCopyState('Сopied')
    }

    return (
        <div className={s.body}>
            <div className={s.link}>
                <div className={s.title}>
                    Copy and send this link to invite people
                </div>

                <div className={s.allBlock} onClick={onClick}>
                    <div className={s.block}>
                        <img src='/ic_link.svg' variant="outline-secondary" className={s.linkImage} />
                        <div className={s.link}>
                            {link}
                        </div>
                    </div>
                    <div className={s.copy}>{copyState}</div>
                </div>
            </div>
        </div >
    );
}

export default withRouter(CopyLink);
