import React from "react";
import styles from "./styles.module.scss";

const Block = ({content, title}) => {

    return (
        <div className={styles.root}>
            <header className={styles.title}>{title}</header>
            {content}
        </div>
    );
}

export default Block;

