import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import PropTypes from 'prop-types';
import Emoji from 'utils/emoji';

const Counter = (props) => {
    const [count, setCount] = useState(2);

    const max = 10;
    const min = 2;

    const incrementCount = () => {
        if (count < max) setCount(count + 1);
    }

    const decrementCount = () => {
        if (count > min) setCount(count - 1);
    }

    useEffect(() => {
        if (props.onChangeCounter) {
            if(count === max){
                props.onChangeCounter('Unlimited');
            } else {
                props.onChangeCounter(count);
            }
        }
    }, [count]);

    return (
        <>
            <div className={styles.title}>Free seats</div>
            <div className={styles.body}>
                <button className='btn' onClick={decrementCount}><Emoji symbol='➖'/></button>
                <div className={styles.count}>{count !== max ? count : '∞'}</div>
                <button className='btn' onClick={incrementCount}><Emoji symbol='➕'/></button>
            </div>
        </>
    );
}

Counter.propTypes = {
    onChangeCounter: PropTypes.func
};

export default Counter;

