import React, { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import styles from './styles.module.scss';
import API, { getAuthorizedHeader } from 'utils/Api';
import User from '../../components/user';
import RateUser from '../rate';
import ReportUser from '../report';
import { toast } from 'react-toastify';
import { joinChatSocketRecieve } from 'utils/socket';
import Emoji from 'utils/emoji';
import { Spinner } from "react-bootstrap";

const MembersHistory = inject("ProfileStore")(observer((props) => {

    const [members, setMembers] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedUserRate, setSelectedUserRate] = useState();
    const [isMembersLoaded, setMembersLoaded] = useState(false);
    const [isReportUserState, setReportUserState] = useState(false);
    const [isLoadingMembersState, setLoadingMembersState] = useState(false);


    useEffect(() => {
        if (isMembersLoaded) {
            let newMembers = members
            joinChatSocketRecieve(props.roomId, (user) => {
                if (user && user._id) {
                    var existedUser = newMembers.find((element) => {
                        return user._id === element._id;
                    });

                    if (!existedUser) { // If there is no user add to items array
                        newMembers = [user, ...newMembers];
                        setMembers(newMembers);
                    }
                }
            });
        }
    }, [isMembersLoaded]);

    useEffect(() => {
        loadMembers()
    }, []);

    const onModalClose = (userRated) => {
        if (userRated) {
            toast.success(`You succesfully have rated ${userRated.username}`);
        }
        setShowDialog(false)
        setReportUserState(false)
        setSelectedUserRate()
    }

    const openReportUserDialog = () => {
        setReportUserState(true);
    }

    const onClickUser = (user) => {
        setSelectedUserRate(user)
        setShowDialog(true);
    }

    const Members = () => {
        const views = members.map((user, index) => {
            return <div className={styles.member} ><User user={user} onClickUser={onClickUser} key={user._id} /></div>
        });
        return views;
    }

    const getCurrentUserId = () => {
        return props.ProfileStore.params.profile._id
    }

    const loadMembers = () => {
        setLoadingMembersState(true)
        const body = {
            roomId: props.roomId
        };

        API.post(
            'thread/history',
            body,
            getAuthorizedHeader(props.ProfileStore.params.accessToken)
        ).then(res => {
            setLoadingMembersState(false)
            setMembersLoaded(true);
            if (res.data && res.data.users) {
                setMembers(res.data.users);
                setMembersLoaded(true);
            }
        }).catch(error => {
            setLoadingMembersState(false)
            console.log('responsehistory ' + error);
            // TODO: handle this error
        });
    }

    const UpdateButton = (isLoading) => {
        if (isLoading) {
            return <div className={styles.updateButton} disabled >
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> 
            </div>
        } else {
            return <button className={styles.updateButton} onClick={loadMembers}><Emoji symbol='🔄' /> Update</button>
        }

    }

    return (
        <div className={styles.body}>
            <div className={styles.titleBlock}>
                <div className={styles.textBlock}>
                    <div className={styles.title}><Emoji symbol='🎞' /> Members history</div>
                    <div className={styles.info}><Emoji symbol='👇' /> Click on a user to rate or report!</div>
                </div>
                {UpdateButton(isLoadingMembersState)}
            </div>
            <section className={styles.members}>
                {Members()}
            </section>

            {showDialog && isReportUserState && // Report user dialog
                <ReportUser
                    onModalClose={onModalClose}
                    show={showDialog}
                    user={selectedUserRate}
                    raterId={getCurrentUserId()} />}

            {showDialog && selectedUserRate && !isReportUserState &&// Rating user dialog
                <RateUser
                    onModalClose={onModalClose}
                    show={showDialog}
                    user={selectedUserRate}
                    openReportUserDialog={openReportUserDialog}
                    raterId={getCurrentUserId()} />}
        </div>
    );
}));

export default MembersHistory;