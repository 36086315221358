import React, { useState } from 'react';
import styles from "./styles.module.scss";
import CreateThread from '../index';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { createNewThreadEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';

const CreateButton = inject("ProfileStore")(observer((props) => {
    const [showDialog, setShowDialog] = useState(false);
    
    const onClick = () => {
        if (props.ProfileStore.params.profile) {
            createNewThreadEvent('click button create');
            setShowDialog(true);
        } else {
            createNewThreadEvent('not authorized');
            toast.warn('🤝 You need a login, in order to create a new thread', {autoClose: 2000});
        }
    }

    const onModalClose = () => {
        setShowDialog(false);
    }

    return (
        <>
            <button block className={styles.button} onClick={onClick} >
                <Emoji symbol='🆕'/> Create new Room
            </button>
            {showDialog && <CreateThread onModalClose={onModalClose} show={showDialog} />}
        </>
    );
}));

export default CreateButton;
