import React, { useState } from "react";
import Avatar from 'react-avatar-edit'
import { Modal, Button } from 'react-bootstrap'
import imageCompression from 'browser-image-compression';
import Emoji from 'utils/emoji';

const ImagePicker = (props) => {
    const [show, setShow] = useState(true);
    const [src, setSrc] = useState('');
    const [preview, setPreview] = useState('');
    const [file, setFile] = useState();

    const handleClose = () => {
        setShow(false);
        props.onModalClose();
    }

    const select = () => {
        props.seletListener(preview);
        props.fileListener(file)
        handleClose();
    }

    const Header = () => {
        return <div><Emoji symbol='😁'/> Select Photo for Avatar</div>
    }

    const onClose = () => {
        setPreview(null);
    }

    const onCrop = (preview) => {
        setPreview(preview);
    }

    async function handleImageUpload(imageFile) {
        const options = {
            maxSizeMB: 5,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options);    
            setFile(compressedFile)

        } catch (error) {
            setFile(imageFile)
        }

    }

    const Body = () => {
        return <>
            <Avatar
                width={390}
                height={295}
                onCrop={onCrop}
                onClose={onClose}
                onFileLoad={handleImageUpload}
                src={src}
            />
        </>
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{Header()}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Body()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={select}>Select</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ImagePicker;