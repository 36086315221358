import React, { useState } from "react"
import styles from './styles.module.scss'
import InfiniteScroll from 'react-infinite-scroller'
import Club from './club'
import { Spinner } from "react-bootstrap"
import API from 'utils/Api'
import Emoji from 'utils/emoji'

const Clubs = () => {

    const [clubs, setClubs] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const loadItems = (page) => {
        API.get(
            'speakingclubs/all',
        ).then(res => {
            const clubs = res.data.data.clubs
            setClubs(clubs)
            setHasMore(false)
        }).catch(error => {
            setHasMore(false)
            console.log(error)
        });
    }

    const Loader = () => {
        return <Spinner className={styles.loader}
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        />
    }

    const Clubs = (clubs) => {
        return <div className={styles.body} md="auto">
            <InfiniteScroll
                pageStart={0}
                loadMore={loadItems}
                hasMore={hasMore}
                loader={Loader()}>
                {clubs && clubs.length > 0 ? (
                    (<div>{
                        clubs.map((club, index) => (
                            <div className={styles.thread}>
                                <Club club={club} key={index} />
                            </div>
                        ))}
                    </div>)
                ) : (
                    !hasMore &&
                    <div className={styles.emptyState}>Whoops, <Emoji symbol='😭' /> Speaking clubs not scheduled yet</div>
                )
                }
            </InfiniteScroll>
        </div>
    }

    return (
        <div className={styles.body}>
            {Clubs(clubs)}
        </div>
    );
}

export default Clubs;