import React, { useState } from "react"
import s from "./styles.module.scss"
import Emoji from 'utils/emoji'
import { withRouter } from 'react-router-dom'

const NavPanel = (props) => {

    const { type } = props

    const onClickRoom = () => {
        const { history } = props;
        if (history) history.push(`/`);
    }

    const onClickClub = () => {
        const { history } = props;
        if (history) history.push(`/speakingclubs`);
    }

    const Clubs = () => <>
        <div className={s.titleNotSelected} onClick={onClickRoom}><Emoji symbol='🌎' /> Online rooms</div>
        <div className={s.titleSelected} onClick={onClickClub}><Emoji symbol='🙊' /> Speaking clubs</div>
    </>

    const Rooms = () => <>
        <div className={s.titleSelected} onClick={onClickRoom}><Emoji symbol='🌎' /> Online rooms</div>
        <div className={s.titleNotSelected} onClick={onClickClub}><Emoji symbol='🙊' /> Speaking clubs</div>
    </>

    const COMPONENT_MAP = {
        rooms: Rooms,
        clubs: Clubs
    }

    const Component = COMPONENT_MAP[type]

    return (
        <div className={s.body}>
            <Component />
        </div>
    )
}

export default withRouter(NavPanel)