import React, { useState } from "react";
import styles from './styles.module.scss';
import FacebookLogin from 'react-facebook-login';
import API from 'utils/Api';
import { getResponseError } from '../validation';
import { toast } from 'react-toastify';


const Facebook = (props) => {
    const [isLoadingState, setLoadingState] = useState(false);
    const [errors, setErrors] = useState(new Map());

    const authFacebook = (userFacebook) => {
        setLoadingState(true);

        API.post(
            'auth/facebook',
            userFacebook,
        ).then(res => {
            setLoadingState(false);
            if (res.data.user) {
                props.onLogined(res.data);
            } else {
                // FIXME: handle this error
                console.log(res);
            }
        }).catch(error => {
            const errors = getResponseError(error);
            setLoadingState(false);
            setErrors(errors);
        });
    }

    const clickFacebook = (response) => {
        if (response) {
            if (response.email) {
                authFacebook(response)
            } else {
                props.needInputEmail(response)
            }
        } else {
            toast.error('Auth Facebook does not work :(')
        }
    }

    return <FacebookLogin
        appId="497142817838149"
        fields="name,email,picture"
        callback={clickFacebook} />
}

export default Facebook;

