import React, { useState, useEffect } from 'react';
import API from 'utils/Api';
import { getResponseError } from '../validation';
import { Button, FormGroup, FormControl, FormLabel, Form, Spinner } from "react-bootstrap";
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import { AUTH_STATE } from '../../../pages/auth/modal';
import { modalEvent } from 'utils/analitycs'

const RecoverPassword = (props) => {
    const [errors, setErrors] = useState(new Map());
    const [isLoadingState, setLoadingState] = useState(false);
    const [isCodeRequested, setCodeRequested] = useState(false);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [token, setToken] = useState('');
    const [timeTitle, setTimeTitle] = useState('');
    const [tokenRecoverPassword, setTokenRecoverPassword] = useState('');

    modalEvent('Recovey password')

    useEffect(() => {
        if (isCodeRequested) {
            Interval();
        }
    }, [isCodeRequested]);

    const validateForm = () => {
        return email.length > 0
    }

    const validateCodeForm = () => {
        return code.length === 4
    }

    const RecoverButton = (isLoading) => {
        if (!isLoading) {
            return <Button block bsSize="large" className={styles.submit} disabled={!validateForm()} type="submit">
                Send</Button>
        } else {

            return <Button block bsSize="large" className={styles.submit} disabled type="submit">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
        }
    }

    const CodeButton = (isLoading) => {
        if (!isLoading) {
            return <Button block bsSize="large" className={styles.submit} disabled={!validateCodeForm()} type="submit">
                Recover</Button>
        } else {

            return <Button block bsSize="large" className={styles.submit} disabled type="submit">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
        }
    }

    const PostRecover = (event) => {
        event.preventDefault();
        setLoadingState(true);

        setErrors(new Map());

        API.post(
            'auth/forgot',
            {
                username: email,
            }
        ).then(res => {
            setLoadingState(false);
            if (res.data.success) {
                setCodeRequested(true);
                setToken(res.data.token);
            } else {
                // FIXME: handle this error
                console.log(res);
            }
        }).catch(error => {
            const errors = getResponseError(error);
            setErrors(errors);
            setLoadingState(false);
        });
    };

    const PostCode = (event) => {
        event.preventDefault();
        setLoadingState(true);

        setErrors(new Map());

        API.post(
            'auth/forgot/code',
            {
                token: token,
                code: code
            }
        ).then(res => {
            setLoadingState(false);
            if (res.data.success) {
                setTokenRecoverPassword(res.data.token);

                const state = AUTH_STATE.NEW_PASSWORD;
                state.token = res.data.token;

                props.onChangeAuthState(state);
            } else {
                // FIXME: handle this error
                console.log(res);
            }
        }).catch(error => {
            setLoadingState(false);
            const errors = getResponseError(error);
            setErrors(errors);
        });
    };

    const Interval = () => {

        const oldDateObj = new Date();
        const newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (5 * 60 * 1000));

        let countDownDate = newDateObj.getTime();

        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeTitle("In " + minutes + "m " + seconds + "s code will expire");

            // If the count down is over, write some text x
            if (distance < 0) {
                clearInterval(x);
                setTimeTitle("😔 Code expired, try again")
                setCodeRequested(false);
            }
        }, 1000);
    }


    return (
        <div className={styles.body}>
            <div id="demo" style={{ textAlign: "center", fontWeight: "bold" }}>{timeTitle}</div>
            <div className={styles.block}>
                {!isCodeRequested ?
                    <form onSubmit={PostRecover} style={{ width: "300px" }}>
                        <div className={styles.titleNotification}>Enter your email or username in the form below and we will send you a code on your email in order to restore password.</div>
                        <FormGroup controlId="username" bsSize="large">
                            <FormLabel>Email or Username</FormLabel>
                            <FormControl
                                type="username"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                isInvalid={!!errors.get('email') || !!errors.get('username')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.get('email') || errors.get('username')}
                            </Form.Control.Feedback>
                        </FormGroup>

                        {RecoverButton(isLoadingState)}
                    </form>
                    :
                    <form onSubmit={PostCode} style={{ width: "300px" }}>
                        <div className={styles.titleNotification}>Type the code from your email</div>
                        <FormGroup controlId="code" bsSize="large">
                            <FormLabel>Code</FormLabel>
                            <FormControl
                                type="code"
                                value={code}
                                maxLength="4"
                                onChange={e => setCode(e.target.value)}
                                isInvalid={!!errors.get('code')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.get('code')}
                            </Form.Control.Feedback>
                        </FormGroup>
                        {CodeButton(isLoadingState)}
                    </form>
                }
            </div>
        </div>
    );
}

export default withRouter(RecoverPassword);