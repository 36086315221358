import React, { useState, useEffect } from "react";
import s from './styles.module.scss';
import ChatView from './chatView';
import CopyLink from './inviteLink';
import UserProfile from '../../../domain/userProfile';
import { withRouter } from 'react-router';
import MembersHistory from '../../components/history';
import { pageEvent } from 'utils/analitycs';
import { inject, observer } from 'mobx-react';
import JoinToChat from './join'
import JoinNotAuthorized from './join/noauthorized'


const ChatPage = inject("ProfileStore")(observer((props) => {
    const [chatState, setChatState] = useState({
        isFromHomePage: false, // User comes from Home page with following params { email, username, title }
        isLoading: true,
        isLoggedIn: false,
        isAuthorized: props.ProfileStore.isAuthorized,
        userData: {}
    });

    const [roomId, setRoomId] = useState(props.match.params.roomId);
    const profile = UserProfile.getUserProfile();

    pageEvent('chat page')
    console.log('roomId ' + roomId)

    useEffect(() => {
        try {
            const { email, username, title } = JSON.parse(localStorage.getItem(roomId));

            const userData = {
                threadId: roomId,
                userId: profile._id,
                email: email,
                username: username, 
                title: title
            }

            setChatState(Object.create({
                isFromHomePage: true,
                isLoading: false,
                isAuthorized: props.ProfileStore.isAuthorized,
                userData: userData
            }));
        } catch (e) {
            setChatState(Object.create({
                isFromHomePage: false,
                isLoading: false,
                isAuthorized: props.ProfileStore.isAuthorized,
            }));
        } finally {
            localStorage.removeItem(roomId)
        }
    }, [roomId]);

    const loggedIn = () => {
        setChatState(Object.create({
            isFromHomePage: false,
            isLoading: false,
            isAuthorized: props.ProfileStore.isAuthorized,
            userData: {
                threadId: roomId,
            }
        }));
    }

    const Body = () => {
        const { isLoading, isFromHomePage } = chatState;

        if (isLoading) {
            return <div className={s.body}>
                Loading
            </div>
        } else if (isFromHomePage) {
            return <div className={s.body}>
                <ChatView clasaName={s.chat}
                    roomName={roomId}
                    userData={chatState.userData}
                    email={chatState.userData.email}
                    username={chatState.userData.username}
                    title={chatState.userData.title} />
                <div className={s.copyLink}>
                    <CopyLink roomId={roomId} />
                </div>
                <div className={s.header}>
                    <MembersHistory roomId={roomId} />
                </div>
            </div>
        } else if (chatState.isAuthorized) { // You came from link (not home page) and you are logged in
            return <JoinToChat roomId={roomId} />
        } else {   // You came from link (not home page) and you are logged in
            return <JoinNotAuthorized roomId={roomId} loggedIn={loggedIn} />
        }
    }

    return <Body />;
}));

export default withRouter(ChatPage);
