const buildLanguageAbilityTitle = (languages, levels, selectedItems) => {
    debugger
    if (selectedItems && selectedItems.length > 0) {

        let items = []
        for (let i = 0; i < selectedItems.length; i++) {
            const lanId = selectedItems[i].language
            const levId = selectedItems[i].level

            const lanObj = getItem(languages, lanId)
            const levObj = getItem(levels, levId)

            items.push({
                level: levObj,
                language: lanObj
            })
        }
        return buildTitle(items.sort(compare))
    }

    return '';
}

export default buildLanguageAbilityTitle

function buildTitle(items) {
    let title = ''
    if (items && items.length > 0) {
        for (let i = 0; i < items.length && i < 3; i++) {
            if (i !== 0) {
                title += '|'
            }

            title += `${items[i].language.flag}${items[i].level.shortTitle}`
        }
    }

    return title
}

function compare(a, b) {
    if (a.level.sort > b.level.sort) return 1;
    if (b.level.sort > a.level.sort) return -1;

    return 0;
}

function getItem(items, id) { // Get language and level
    for (let i = 0; i < items.length; i++) {
        if (items[i]._id === id) {
            return items[i]
        }
    }
}