
import { action, observable, computed } from 'mobx';
import UserProfile from 'domain/userProfile';

export default class ProfileStore {

    @observable params = {
        profile: UserProfile.getUserProfile(),
        accessToken: UserProfile.getAccessToken()
    };

    @computed get isAuthorized() {
        return this.params.profile != null && this.params.profile !== undefined;
    }

    @action setProfile = (user) => {
        UserProfile.setUserProfile(user);   
        this.params.profile = user;
    }

    @action setAccessToken = (token) => {
        UserProfile.setAccessToken(token);   
        this.params.accessToken = token;
    }

    @action clearProfile = () => {
        UserProfile.clearUserProfile();
        this.params.profile = null;
        this.params.accessToken = '';
    }
}