import React, { useState } from 'react'
import s from "./styles.module.scss"
import { withRouter } from 'react-router-dom'
import { Button, FormGroup, FormControl, FormLabel, Form, Spinner } from "react-bootstrap"
import API from 'utils/Api';
import { getResponseError } from '../validation';
import { validateEmail } from 'utils/utils';
import { toast } from 'react-toastify';
import { modalEvent } from 'utils/analitycs'

const InputEmail = (props) => {
    const [errors, setErrors] = useState(new Map())
    const [isLoadingState, setLoadingState] = useState(false)
    const [email, setEmail] = useState('')

    modalEvent('Input email facebook')

    const putEmail = (event) => {
        event.preventDefault();
        setLoadingState(true);

        setErrors(new Map());
        setLoadingState(true);

        if (props.user) {
            props.user.email = email
            API.post(
                'auth/facebook',
                props.user,
            ).then(res => {
                setLoadingState(false);
                if (res.data.user) {
                    props.onLogined(res.data);
                } else {
                    setLoadingState(false);
                    toast.error('Something went wrong')
                }
            }).catch(error => {
                const errors = getResponseError(error);
                setLoadingState(false);
                setErrors(errors);
            });
        } else {
            setLoadingState(false);
            toast.error('Something went wrong')
        }
    };

    const validateForm = () => {
        return email.length > 0 && validateEmail(email)
    }

    const AcceptButton = (isLoading) => {
        if (!isLoading) {
            return <Button block bsSize="large" className={s.submit} disabled={!validateForm()} type="submit">
                Send</Button>
        } else {

            return <Button block bsSize="large" className={s.submit} disabled type="submit">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
        }
    }

    return (
        <div className={s.body}>

            <div className={s.block}>
                <form onSubmit={putEmail} style={{ width: "300px" }}>
                    <div className={s.titleNotification}>Your facebook account does not have an attached email</div>
                    <FormGroup controlId="username" bsSize="large">
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            type="username"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            isInvalid={!!errors.get('email')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.get('email')}
                        </Form.Control.Feedback>
                    </FormGroup>

                    {AcceptButton(isLoadingState)}
                </form>
            </div>
        </div>
    );
};

export default withRouter(InputEmail);
