import axios from 'axios'
import { ARG_AUTHORIZED, ARG_ACCESS_TOKEN } from '../domain/userProfile'

export const baseApiURL = process.env.REACT_APP_BASE_URL + '/api'
const instance = axios.create({ baseURL: baseApiURL })
export default instance

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        logOut()
    }
    return Promise.reject(error);
});

function logOut() {
    localStorage.removeItem(ARG_AUTHORIZED);
    localStorage.removeItem(ARG_ACCESS_TOKEN);

    window.location.replace("/");
}

export function getAuthorizedHeader(token) {
    if (token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        return {
            headers: headers
        }
    }
}