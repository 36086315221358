/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { joinChatSocket, leftChatSocket } from 'utils/socket';

const ChatView = props => {
    const [alreadyLeft, setAlreadyLeft] = useState(false);

    let domain = '';
    let options = '';
    let { email, username, title } = props;

    useEffect(() => {
        domain = 'meet.jit.si';

        options = {
            roomName: props.roomName,
            width: '100%',
            height: 800,
            configOverwrite: {
                disableDeepLinking: true,
                prejoinPageEnabled: false,
                startWithVideoMuted: true,
            },
            userInfo: {
                email: email
            },
            interfaceConfigOverwrite: {
                HIDE_INVITE_MORE_HEADER: true,
                SHOW_JITSI_WATERMARK: false,
                TOOLBAR_ALWAYS_VISIBLE: true,
                SHOW_CHROME_EXTENSION_BANNER: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                SHOW_POWERED_BY: false,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                TOOLBAR_BUTTONS: [
                    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                    'fodeviceselection', 'hangup', 'chat',
                    'etherpad', 'sharedvideo', 'raisehand',
                    'videoquality', 'filmstrip', 'feedback', 'stats',
                    'tileview', 'download'
                ],
            }
        }
    }, []);

    useEffect(() => {
        let api = new window.JitsiMeetExternalAPI(domain, options);
        api.executeCommand('password', props.roomName);

        api.executeCommand('displayName', username);
        const theme = title ? title : 'Speaking';
        api.executeCommand('subject', theme);

        api.addEventListener('videoConferenceJoined', joinSocket);
        api.addEventListener('videoConferenceLeft', leftSocket);
    }, []);

    const leftSocket = (param) => {
        if (!alreadyLeft) {
            leftChatSocket(props.userData);
        }
        setAlreadyLeft(true)
        closeTab();
    }

    const joinSocket = (param) => {
        joinChatSocket(props.userData);
    }

    function closeTab() {
        window.open("about:blank", "_self");
        window.close();
    }

    return (
        <div className={styles.body}></div>
    );

}

export default ChatView;
