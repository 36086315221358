import React, { useState } from 'react';
import styles from "./styles.module.scss";
import { Modal, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap'
import API, { getAuthorizedHeader } from 'utils/Api';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import Emoji from 'utils/emoji';

const CheckBox = props => {
    return (
        <div className={styles.checkBox}>
            <input style={{ marginRight: '5px' }} key={props.id} onChange={props.handleCheckElement} type="checkbox" checked={props.isChecked} value={props.value} /> {props.value}
        </div>
    )
}

const ReportUser = inject("ProfileStore")(observer((props) => {
    const { user, raterId } = props;

    const [show, setShow] = useState(true)
    const [isAgremmentChecked, setAgremmentChecked] = useState(false)
    const [comment, setComment] = useState('')
    const [selectedReason, setSelectedReason] = useState()
    const [isLoadingState, setLoadingState] = useState(false)

    const [reports, setReports] = useState([
        { id: 1, value: "Trolling", isChecked: false },
        { id: 2, value: "Sharing inappropriate content or topic", isChecked: false },
        { id: 3, value: "Disrespectful", isChecked: false },
        { id: 4, value: "Sharing inappropriate video", isChecked: false },
        { id: 5, value: "Another reason", description: '', isChecked: false }
    ]);

    const handleClose = () => {
        setShow(false);
        props.onModalClose();
    }

    const handleCheckElement = (event) => {

        let reportsLocal = [...reports];

        reportsLocal.forEach(item => {
            if (item.value === event.target.value) {
                setSelectedReason(item)
                item.isChecked = event.target.checked
            } else {
                item.isChecked = false
            }
        })
        setReports(reportsLocal)
    }

    const validateData = () => {
        let isChecked = false
        reports.forEach(item => {
            if (item.isChecked) isChecked = true
        })

        if (!isChecked) {
            toast.error('You have to choose the reason!');
            return false
        }

        if (comment.length < 10) {
            toast.error('Comment has to be more than 10 characters!')
            return false
        }

        return true
    }

    const rateUser = async () => {
        if (validateData()) {

            const body = {
                "user": user._id,
                "rater": raterId,
                "comment": comment,
                "reason": selectedReason.value,
            };

            setLoadingState(true)

            API.post(
                'user/report',
                body,
                getAuthorizedHeader(props.ProfileStore.params.accessToken)
            ).then(res => {
                setLoadingState(false)

                setShow(false);
                props.onModalClose(user);
            }).catch(error => {
                setLoadingState(false)

                if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
                    toast.error(error.response.data.data.message);
                } else {
                    toast.error(error.message);
                }
            });
        }
    }

    const onTextAreaChanged = (event) => {
        setComment(event.target.value);
    }

    const Body = () => {
        return <div className={styles.body}>
            <div className={styles.titleAgreement}>
                <span className={styles.titleAttention}>Attention! </span>
                In case of false information, you will be blocked!
            </div>

            <div className={styles.checkBlockAgreement}>
                <input className={styles.checkBox} onChange={() => {
                    setAgremmentChecked(!isAgremmentChecked);
                }} type="checkbox" value={props.value} />
                <div style={{ color: '#25292e', marginLeft: '10px', fontWeight: 'bold' }}>I agree</div>
            </div>


            <div className={styles.commentTitle}>Reasons</div>
            <div className={styles.checkBoxBlock}>
                {
                    reports.map((report) => {
                        return (<CheckBox handleCheckElement={handleCheckElement}  {...report} />)
                    })
                }
            </div>

            <div className={styles.commentTitle}>Comment</div>
            <InputGroup className="mb-3" >
                <FormControl
                    id="inputTheme"
                    placeholder="Describe the reason"
                    aria-label="Theme"
                    aria-describedby="basic-addon1"
                    rows="5"
                    onChange={onTextAreaChanged}
                />
            </InputGroup>

        </div>
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }}><Emoji symbol='🚫'/> Report user - {user.username}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Body()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    {isAgremmentChecked ?
                        isLoadingState ? <div className={styles.activeButton} disabled >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div> : <Button variant="primary" onClick={rateUser}>Report</Button> :
                        <Button variant="primary" disabled >Report</Button>

                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}));

export default ReportUser;
