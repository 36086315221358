import React from "react";
import styles from "./styles.module.scss";

const LotaspeakButton = (props) => {

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return <button onClick={onClick} className={styles.button}>{props.title}</button>
}

export default LotaspeakButton;
