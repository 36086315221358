/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import s from "./styles.module.scss"
import { withRouter, Link } from 'react-router-dom'
import { footerEvents } from 'utils/analitycs'
import Emoji from 'utils/emoji';


const Footer = (props) => {
    const [isContactWithUsClicked, setContactWithUsClicked] = useState(false)

    return (
        <div className={s.footerBody} id='idFooterBody'>

            <div className={s.divider}></div>

            <div className={s.titleSlogan}>Linguaspeak, {new Date().getFullYear()}</div>

            <a className={s.whatIsit} href='/about' onClick={() => { footerEvents('what is it') }}>What is it?</a>

            <div className={s.subscribeTitle}>Subscribe to us</div>
            <div className={s.social}>
                <a target="_blank" href="https://www.facebook.com/groups/2682093192037817" onClick={() => { footerEvents('facebook') }}>
                    <img
                        alt=""
                        src="/ic_facebook.svg"
                        width="30"
                        height="30"
                        className={s.item}
                    />
                </a>

                <a target="_blank" href="https://www.instagram.com/linguaspeak_com/" onClick={() => { footerEvents('instagram') }}>
                    <img
                        alt=""
                        src="/ic_instagram.svg"
                        width="30"
                        height="30"
                        className={s.item}
                    />
                </a>

                {isContactWithUsClicked ?
                    <div className={s.emailTitle}>write here <Emoji symbol='👉' /> <span className={s.email}>lingspeak@gmail.com</span></div>
                    : <div className={s.contactWithUs} onClick={() => setContactWithUsClicked(true)}>Contact us</div>
                }
                {/* <a className={s.whatIsit} href='/en/blog/' onClick={() => { footerEvents('our blog') }}>Our Blog</a> */}
                {/* <a className={s.whatIsit} href='/en/blog/' onClick={() => { footerEvents('our blog') }}>Our Blog</a> */}
                <Link className={s.whatIsit} to='/en/blog/'>Our Blog</Link>
            </div>

            <div className={s.titlePrivacy}>The site uses <a target='_blank' href="https://en.wikipedia.org/wiki/HTTP_cookie" onClick={() => { footerEvents('cookie') }}>cookie</a> for authorization <br></br>and collects <a target='_blank' href="/docs/privacy_policy" onClick={() => { footerEvents('privacy policy') }}>anonymous data</a> for statistics.</div>
        </div>
    );
};

export default withRouter(Footer);
