import React from 'react'
import styles from "./styles.module.scss";
import User from '../../user'
import Emoji from 'utils/emoji';

const Members = ({ members, isRemovable }) => {

    const Member = (user, key, sizeImage) => <User user={user} sizeImage={sizeImage} className={styles.user} key={key} />

    const Members = (members) => {
        // members = dumbMembers(members);
        if (members.length < 6) {
            return fiveMembers(members, 80);
        } else {
            return moreTenMembers(members, 40);
        }
    }

    function dumbMembers(members) {
        let items = [...members];
        // items.push(...items)
        // items.push(...items)
        return items;
    }

    const fiveMembers = (members, sizeImage) => {
        let views = members.map((member, index) => {
            return Member(member, index, sizeImage);
        });

        return <div className={styles.members}>{views}</div>
    }

    const moreTenMembers = (members, sizeImage) => {

        const lentgh = members.length > 20 ? 20 : members.length;
        const mid = (lentgh / 2) + (lentgh % 2);
        const end = lentgh;

        const membersFist = members.slice(0, mid);
        const membersSecond = members.slice(mid, end);

        const viewsFirstPart = fiveMembers(membersFist, sizeImage);
        const viewsSecondPart = fiveMembers(membersSecond, sizeImage);

        return <div className={styles.moreTenMembers}>
            <div>{viewsFirstPart}</div>
            <div>{viewsSecondPart}</div>
        </div>
    }

    const Empty = (isRemovable) => {
        const title = isRemovable
            ? 'Empty thread during one minute will be removed'
            : 'Join and be first in this thread'

        const emoji = isRemovable ? '💺' : '🤗'
        return <div className={styles.emptyRoom}><Emoji symbol={emoji} /> {title}</div>
    }

    return <div>
        {members && members.length > 0 ? Members(members) : Empty(isRemovable)}
    </div>;
}

export default Members;