import React from 'react'
import styles from "./styles.module.scss";
import User from '../../../user'
import Emoji from 'utils/emoji';

const Members = ({ members, author }) => {

    let sizeImage = 80

    const Member = (user, key, sizeImage, isHost) => <User
        user={user}
        sizeImage={sizeImage}
        isHost={isHost}
        className={styles.user} key={key}
    />

    const Members = (members) => {
        // members = dumbMembers(members)
        let authorId = author ? author._id : 0

        let views = []
        for (let i = 0; i < members.length; i++) {
            views.push(Member(members[i], i, sizeImage, authorId === members[i]._id))
        }

        return <div className={styles.members} id='members-block'>{views}</div>
    }

    function dumbMembers(members) {
        let items = [...members]
        items.push(...items)
        items.push(...items)
        items.push(...items)
        items.push(...items)
        items.push(...items)
        // items.push(...items)
        items.push(items[0])
        // items.push(items[0])
        // items.push(items[0])
        // items.push(items[0])
        return items
    }

    const Empty = () => {
        const title = 'Join and be first in this speaking club'
        return <div className={styles.emptyRoom}><Emoji symbol='💺' /> {title}</div>
    }

    return <div>
        {members && members.length > 0 ? Members(members) : Empty()}
    </div>;
}

export default Members;