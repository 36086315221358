import SocketIO from "socket.io-client";

const chatEndpoint = `${process.env.REACT_APP_BASE_URL}/chat`

const chatSocket = SocketIO(chatEndpoint);

export const joinChatSocket = function (dataUser) {
    chatSocket.emit('userJoin', dataUser);
};

export const leftChatSocket = function (dataUser) {
    dataUser.isRemoveMode = true;
    chatSocket.emit('userLeft', dataUser);
};

// Subscribe on event wher new user join to chat, getting user for history component
export const joinChatSocketRecieve = function (roomId, callback) {
    const EVENT = `ADD_USER_TO_HISTORY_${roomId}`
    chatSocket.on(EVENT, ({ data }) => {
        callback(data.user);
    });
} 