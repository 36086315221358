import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import { inject, observer } from 'mobx-react';
import { pageEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';
import { englishLanguageId, spanishLanguageId } from './subscribeTelegram'

const SubscribeDialogTelegram = inject("ProfileStore")(observer((props) => {
    pageEvent('subscribe telegram dialog')

    const [show, setShow] = useState(true)

    const { languageId } = props

    let data

    if (languageId === spanishLanguageId) {
        data = {
            title: "Linguaspeak.Spanish",
            flag: "🇪🇸",
            "language": "spanish",
            "link": "https://t.me/linguaspeak_spanish"
        }
    } else {
        data = {
            title: "Linguaspeak.English",
            flag: "🇺🇸",
            "language": "english",
            "link": "https://t.me/linguaspeak_english"
        }
    }

    const handleClose = () => {
        setShow(false);
        props.onModalClose()
    }

    const goTo = () => {
        function openNewTab(url) {
            const win = window.open(url, '_blank');
            if (win != null) {
                win.focus();
            }
        }

        openNewTab(data.link);
        handleClose();
    }

    const Body = () => {
        const { flag, title, language } = data
        return <>
            <div>You can subscribe in Telegram on channel <br></br><br></br><strong>{title}</strong>
                <Emoji symbol={flag} /><br></br><br></br>  in order to get notification from Telegram when people will join to {language} speaking room.
            </div>

            <br></br>
            <p>
                <strong>
                    You will not get any spam from this channel, only notifications about joining of other linguaspeakers<Emoji symbol='❗️' />
                </strong>
            </p>
        </>
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }}><Emoji symbol='📍' /> Subscribe</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Body()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={goTo}>Subscribe</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}));

export default withRouter(SubscribeDialogTelegram);