import React, { useState } from 'react'
import { Navbar } from 'react-bootstrap'
import AuthPopup from '../../pages/auth/modal'
import Profile from '../views/profileBadge'
import styles from "./styles.module.scss";
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { navigationActiveButtonEvent } from 'utils/analitycs'

export const NAV_STATE = {
    EMPTY: { value: 0 },
    LOGIN: { value: 1 },
    LANDING: { value: 2 },
    AUTHORIZED: { value: 4 },
};

export const handleStartClick = (props) => {
    navigationActiveButtonEvent('try it now')
    localStorage.setItem('comgins_soon_page', true)

    const { history } = props;
    if (history) history.push(`/feed`);
}

const NavBar = inject("ProfileStore")(observer((props) => {
    const [isAuthPopupVisible, setAuthPopupVisible] = useState(false);

    const handleLoginClick = () => {
        navigationActiveButtonEvent('login')
        setAuthPopupVisible(!isAuthPopupVisible)
    }

    const routeMain = () => {
        const { history } = props;
        if (history) history.push(`/feed`);
    }

    const handleContinue = () => {
        navigationActiveButtonEvent('continue practicing')
        localStorage.setItem('comgins_soon_page', true)
        routeMain(props)
    }

    const authBlock = (navState, profile) => {
        if (navState && navState.value !== NAV_STATE.EMPTY.value) {
            if (navState.value === NAV_STATE.LOGIN.value) {
                if (profile) {
                    return <Navbar.Collapse className="justify-content-end">
                        <Profile user={profile} />
                    </Navbar.Collapse>
                } else {
                    return <Navbar.Collapse className="justify-content-end">
                        <div className={styles.itemsNav}>
                            <div className={styles.login} onClick={handleLoginClick}>Login</div>
                        </div>
                    </Navbar.Collapse>
                }
            } else if (navState.value === NAV_STATE.LANDING.value) {
                if (!profile) {
                    return <Navbar.Collapse className="justify-content-end">
                        <div className={styles.itemsNav}>
                            <div className={styles.button} onClick={() => handleStartClick(props)}>Get started</div>
                            <div className={styles.login} onClick={handleLoginClick}>Login</div>
                        </div>
                    </Navbar.Collapse>
                } else {
                    return <Navbar.Collapse className="justify-content-end">
                        <div className={styles.itemsNav}>
                            <div className={styles.button} onClick={handleContinue}>Continue</div>
                        </div>
                    </Navbar.Collapse>
                }
            }
        }
    }

    return (
        <>
            <Navbar className={styles.colorNav} variant="light"  >
                <Navbar.Brand className={styles.brandTitle} href="/">
                    <img
                        alt=""
                        src="/lotaspeak.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{'   '}
                    Linguaspeak
                    </Navbar.Brand>

                {/* props.isEmptyBar на каких то экранах нет authBlock */}
                {authBlock(props.navState, props.ProfileStore.params.profile)}

                {isAuthPopupVisible && <AuthPopup route={() => routeMain(props)} />}
            </Navbar>
            <div className={styles.divider}></div>
        </>
    )
}));

export default withRouter(NavBar);
