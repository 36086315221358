/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import s from "./styles.module.scss"
import { withRouter } from 'react-router-dom'
import Footer from "../../components/footer"
import { pageEvent } from 'utils/analitycs'
import { handleStartClick } from '../../components/navigation'
import Emoji from 'utils/emoji'

const ComingSoon = (props) => {

    useEffect(() => {
        pageEvent('landing page')
    }, [])

    const Items = () => {
        return <div className={s.bodyItems}>
            <div className={s.orange}>Find buddies in one click</div>
            <div className={s.gray}>Discuss interesting topics</div>
            <div className={s.purple}>Speak to highly-rated users</div>
        </div>
    }

    const What = () => {
        return <div className={s.prosBlock}>
            <div className={s.textInsiseBlock}>‍<Emoji symbol='💼' /> work or study abroad</div>
            <div className={s.textInsiseBlock}>‍<Emoji symbol='📄' /> improve speaking for an exam</div>
            <div className={s.textInsiseBlock}>‍<Emoji symbol='🤝 ' /> find a foreign friend</div>
        </div>
    }

    const HowItWorks = () => {
        return <div className={s.howItWorksBlock}>
            <div className={s.firstStep}>
                <div className={s.textFirstStep}>1. Create your profile</div>
                <img className={s.imgFirstStep} src="/ic_hiw_first_step.png" />
            </div>

            <div className={s.secondStep}>
                <div className={s.textSecondStep}>2. Find a chat room for your target language and topic</div>
                <img className={s.imgSecondStep} src="/ic_hiw_second_step.png" />
                <div className={s.textSecondStep}>or create your own room with custom settings and rules</div>
                <img className={s.imgSecondStep} src="/ic_hiw_second_step_2.png" />
            </div>

            <div className={s.thirdStep}>
                <div className={s.textThirdStep}>3. Talk to natives, be polite, and rate other users</div>
                <img className={s.imgThirdStep} src="/ic_hiw_third_step.png" />
            </div>
        </div>
    }

    const HeroSection = () => {
        return <div className={s.heroBlock}>
            <div className={s.subtitle}>
                <span className={s.title}>How does it work</span><p>Join chat rooms for different languages and different themes.</p>
            </div>

            <div className={s.subtitle}>
                <span className={s.title1}>Pricing</span><p>Absolutely free</p>
            </div>

            <div className={s.button} onClick={() => handleStartClick(props)}>Get started <Emoji symbol='👉' /></div>
        </div>
    }

    return (
        <div className={s.body}>
            <div className={s.titleSlogan}>Learn foreign languages<br></br>by speaking<br></br>with natives</div>

            <HeroSection />

            <div className={s.subtitleBlack}>How it works in 3 steps</div>
            <HowItWorks />

            <div className={s.subtitleBlack}>Improve your fluency and achieve your goals</div>
            <What />

            <div className={s.subtitleBlack}>Find natives in your target language</div>
            <Items />

        
            <Footer />
        </div>
    );
};

export default withRouter(ComingSoon);
