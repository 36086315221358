import React from 'react';
import styles from "./styles.module.scss";
import { withRouter } from 'react-router-dom';
import { getRefreshedUrl } from 'utils/user'

const ProfileBadge = (props) => {
    const avatar = props.user.image && props.user.image.url ? getRefreshedUrl(props.user.image.url) : '/avatar-thin.svg';

    function buildName({ firstName, secondName, username }) {
        let name = '';
        if (firstName && secondName) {
            name = `${firstName} ${secondName}`;
        } else {
            name = username;
        }
        return name;
    }

    const onClickProfile = () => {
        const { history } = props;
        if (history) history.push(`/user/${props.user.username}/${props.user._id}`);
    }

    return (
        <div className={styles.body} onClick={onClickProfile}>
            <img className={styles.image} src={avatar} alt='icon' />
            <div className={styles.name}>{buildName(props.user)}</div>
        </div>
    );
}

export default withRouter(ProfileBadge);