import React from "react";
import { Button, FormGroup, FormControl, FormLabel, Form, Spinner } from "react-bootstrap";
import styles from "./styles.module.css";
import PropTypes from 'prop-types';
import { AUTH_STATE } from '../../../pages/auth/modal';
import API from 'utils/Api';
import { getResponseError } from '../validation';
import Facebook from '../social/facebook'
import { modalEvent } from 'utils/analitycs'

export default class Login extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: new Map(),
      isLoadingState: false,
    }

    modalEvent('Login')
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setLoadingState(true);

    const dataBody = {
      username: this.state.email,
      password: this.state.password,
    };

    API.post(
      'auth/login',
      dataBody,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(res => {
      this.setLoadingState()
      if (res.data.user) {
        this.props.onLogined(res.data);
      } else {
        // FIXME: handle this error
        console.log(res);
      }
    }).catch(error => {
      const errors = getResponseError(error);
      this.setState({ errors: errors, isLoadingState: false });
    });
  }

  setLoadingState = (state = false) => {
    this.setState({
      isLoadingState: state
    });
  }

  renderButtonState = (isLoading) => {
    if (!isLoading) {
      return <Button block bsSize="large" className={styles.buttonRegister} disabled={!this.validateForm()} type="submit">
        Login</Button>
    } else {

      return <Button block bsSize="large" className={styles.buttonRegister} disabled type="submit">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </Button>
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div className={styles.body}>
        <Facebook onLogined={this.props.onLogined} needInputEmail={this.props.needInputEmail}/>
        <div className={styles.title}>Or Login with email</div>
        <form onSubmit={this.handleSubmit}>

          <FormGroup controlId="username" bsSize="large">
            <FormLabel>Email or Username</FormLabel>
            <FormControl
              type="username"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              isInvalid={!!errors.get('email') || !!errors.get('username')}
            />
            <Form.Control.Feedback type="invalid">
              {errors.get('email') || errors.get('username')}
            </Form.Control.Feedback>
          </FormGroup>


          <FormGroup controlId="password" bsSize="large">
            <FormLabel>Password</FormLabel>
            <FormControl
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              isInvalid={!!errors.get('password')}

            />
            <Form.Control.Feedback type="invalid">
              {errors.get('password')}
            </Form.Control.Feedback>
          </FormGroup>

          {this.renderButtonState(this.state.isLoadingState)}

        </form>
        <div className={styles.signup}>Not a member?
           <span
            className={styles.signUpTitle}
            onClick={() => this.props.onChangeAuthState(AUTH_STATE.REGISTER)}>
            Sign up
          </span>
        </div>
        <div style={{ textAlign: 'center' }}>
          or<span className={styles.recoverPassword}onClick={() => this.props.onChangeAuthState(AUTH_STATE.RESTORE_PASSWORDD)}>Forgot password?</span>
        </div>

      </div>
    );
  }
}

Login.propTypes = {
  onLogined: PropTypes.func,
  onChangeAuthState: PropTypes.func,
};