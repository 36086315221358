import React from 'react';
import './App.css';
import RouterApp from './Router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';

export default class App extends React.Component {

  componentDidMount() {
    ReactGA.initialize('UA-162888880-1', false);
  }

  render() {
    return (
      <>
        <YMInitializer accounts={[68879452]} options={{webvisor: true}}/>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover={false}
        />
        <RouterApp />
      </>
    );
  }
};