import React from "react";
import s from "./styles.module.scss";
import { pageEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';

const BlogList = () => {
    pageEvent('blog list')

    const articles = [
        {
            image: 'https://storage.yandexcloud.net/lotaspeak-images/project/img_how_to_find_partners_on_linguspeak.png',
            alt: 'Speaking any language online can be made really with simple and effective steps, which we will show you in this guide. Keep reading to find out more.',
            title: 'How to find partners for speaking on Linguapeak?',
            link: '/en/blog/how-to-find-partners-on-linguaspeak'
        },
        {
            image: 'https://storage.yandexcloud.net/lotaspeak-images/project/img_how_to_practice_speaking.jpg',
            alt: 'Speaking any language online can be made really with simple and effective steps, which we will show you in this guide. Keep reading to find out more.',
            title: 'How to Practice Speaking Online',
            link: '/en/blog/how-to-practice-speaking-online'
        }
    ]

    const Articles = (articels) => {
        let views = []

        for (let i = 0; i < articels.length; i++) {
            views.push(Article(articels[i]))
        }

        return views
    }

    const Article = ({ image, alt, title, link }) => {
        return <div className={s.articleBlock}>
            <a href={link} className={s.story} >
                <img src={image} alt={alt} className={s.storyStretchImg} />
                <span className={s.storyInfo}>
                    <span className={s.title}>{title}</span>
                </span>
            </a>
        </div>
    }

    return (
        <>
            <div className={s.body}>
                <div className={s.header}><Emoji symbol='💻' /> Linguaspeak.Blog</div>

                {Articles(articles)}
            </div>
        </>
    );
}

export default BlogList;

