import React, { useState } from "react"
import styles from './styles.module.scss'
import InfiniteScroll from 'react-infinite-scroller'
import ThreadView from './thread'
import { Spinner } from "react-bootstrap"
import API from 'utils/Api'
import Emoji from 'utils/emoji'

const ThreadsView = () => {

    const [threads, setThreads] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const loadItems = (page) => {
        API.get(
            'thread/all',
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            const threads = res.data.data.threads;
            setThreads(threads);
            setHasMore(false);
        }).catch(error => {
            setHasMore(false);
            console.log(error);
        });
    }

    const Loader = () => {
        return <Spinner className={styles.loader}
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        />
    }

    const renderThreads = (threads) => {
        return <div className={styles.body} md="auto">
            <InfiniteScroll
                pageStart={0}
                loadMore={loadItems}
                hasMore={hasMore}
                loader={Loader()}
            >
                {threads && threads.length > 0 ? (
                    (<div>{
                        threads.map((thread, index) => (
                            <div className={styles.thread}>
                                <ThreadView thread={threads[index]} key={index} />
                            </div>
                        ))}
                    </div>)
                ) : (
                        !hasMore &&
                        <div className={styles.emptyState}>Whoops, <Emoji symbol='🐶' /> dog has eaten all threads, you can create new one!</div>
                    )
                }
            </InfiniteScroll>
        </div>
    }

    return (
        <div className={styles.body}>
            {renderThreads(threads)}
        </div>
    );
}

export default ThreadsView;