import React from 'react'
import styles from './styles.module.scss'
import Members from '../members'
import { withRouter } from 'react-router-dom'
import ButtonJoin from '../button'
import SubscribeTelegram from '../button/subscribeTelegram.js'
import Levels from '../../views/levels'
import Emoji from 'utils/emoji';

const ThreadView = (props) => {
    const { thread, key, joinEvent } = props;

    const onClickInfo = () => {
        //TODO: open thread card
    }

    const FreePlaceTitle = () => {
        let count = 'Unlimited';
        if (props.thread.numberSeats !== 'Unlimited') {
            count = props.thread.numberSeats - props.thread.members.length
        }
        return <div className={styles.freeSeat}><Emoji symbol='💺' /> Free places: {count}</div>
    }

    return (
        <div className={styles.body} key={key} onClick={onClickInfo}>
            <div className={styles.header}>
                <div>
                    <div className={styles.title}><Emoji symbol={thread.language.flag} /> {thread.language.title}</div>

                    <Levels
                        levelsThread={thread.level}
                        threadLanguage={thread.language}
                    />

                    {thread.title && <div className={styles.themeDiv}><span className={styles.themeTitle}><Emoji symbol='💬' /> Theme: </span>"{thread.title}"</div>}
                </div>
                <FreePlaceTitle />
            </div>

            {/* TODO: try load these members separatly in every thread */}
            <Members members={thread.members} />

            <div className={styles.footer}>
                <ButtonJoin thread={thread} joinEvent={joinEvent} />
                <SubscribeTelegram thread={thread} />
            </div>
        </div>
    );
}

export default withRouter(ThreadView);