import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// Components
import HomePage from './ui/pages/home';
import SpeakingClubsPage from './ui/pages/home/speakingClubs';
import ClubPage from './ui/pages/club';
import ChatRoomPage from './ui/pages/chat';
import ProfilePage from './ui/pages/profile';
import CreateThreadPage from './ui/pages/create';
import UpdateUser from './ui/components/auth/update';
import NotFoundPage from './ui/pages/404';
import NavigationBar, { NAV_STATE } from './ui/components/navigation';
import ComingSoon from './ui/pages/landing';
import PrivacyPolicy from './ui/pages/docs/privacyPolicy';
import Footer from "./ui/components/footer";
import LevelsInfo from "./ui/pages/info/level";
import About from "./ui/pages/about";
import ArticleHowToPracticeSpeaking from "./ui/pages/blog/howPracticeEnglish";
import FindPartnersOnLinguaspeakArticle from "./ui/pages/blog/findPartners";
import BlogList from "./ui/pages/blog/blogList";

const RouterApp = (props) => {

    const NavBar = (navState = NAV_STATE.LOGIN) => {
        return <NavigationBar navState={navState} />
    }

    return (
        <Router >
            <div>
                <Switch>
                    <Route path="/en/blog/how-to-practice-speaking-online">
                        {NavBar()}
                        <ArticleHowToPracticeSpeaking />
                        <Footer />
                    </Route>

                    <Route path="/en/blog/how-to-find-partners-on-linguaspeak">
                        {NavBar()}
                        <FindPartnersOnLinguaspeakArticle />
                        <Footer />
                    </Route>

                    <Route exact path="/en/blog/">
                        {NavBar()}
                        <BlogList />
                        <Footer />
                    </Route>

                    <Route exact path="/feed">
                        {NavBar()}
                        <HomePage />
                        <Footer />
                    </Route>

                    <Route exact path="/about">
                        {NavBar()}
                        <About />
                        <Footer />
                    </Route>

                    <Route exact path="/speakingclub/:clubId">
                        {NavBar()}
                        <ClubPage />
                        <Footer />
                    </Route>

                    <Route exact path="/speakingclubs">
                        {NavBar()}
                        <SpeakingClubsPage />
                        <Footer />
                    </Route>

                    <Route exact path="/chat/:roomId" component={ChatRoomPage} />

                    <Route path="/thread/create">
                        {NavBar()}
                        <CreateThreadPage />
                    </Route>

                    <Route path="/auth/update">
                        {NavBar(NAV_STATE.EMPTY)}
                        <UpdateUser />
                        <Footer />
                    </Route>

                    <Route exact path="/user/:username/:id" >
                        {NavBar(NAV_STATE.AUTHORIZED)}
                        <ProfilePage />
                        <Footer />
                    </Route>

                    {/* {!localStorage.getItem('comgins_soon_page') &&
                        < Route path="/">
                            {NavBar(NAV_STATE.LANDING)}
                            <ComingSoon />
                        </Route>
                    } */}

                    <Route path="/landing">
                        {NavBar(NAV_STATE.LANDING)}
                        <ComingSoon />
                    </Route>

                    <Route exact path="/docs/privacy_policy">
                        {NavBar(NAV_STATE.EMPTY)}
                        <PrivacyPolicy />
                        <Footer />
                    </Route>

                    <Route exact path="/info/levels">
                        {NavBar(NAV_STATE.EMPTY)}
                        <LevelsInfo />
                        <Footer />
                    </Route>

                    <Route exact path="/">
                        {NavBar()}
                        <HomePage />
                        <Footer />
                    </Route>

                    <Route path="/404" >
                        {NavBar()}
                        <NotFoundPage />
                        <Footer />
                    </Route>

                    <Redirect to="/404" />
                </Switch>
            </div>
        </Router >
    );
}

export default RouterApp;
