
export function buildName({ username, firstName, lastName }) {
    let name = ''

    if (firstName) {
        name = firstName;
    }

    if (lastName) {
        name = `${name} ${lastName}`;
    }

    if (name === '') {
        name = username
    }

    return name;
}

export function getRefreshedUrl(url) {
    if (url) {
        url = `${url}?${new Date().getTime()}`
    }
    return url
}