import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { withRouter } from 'react-router-dom';
import { Modal, Button, Dropdown, InputGroup, FormControl, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types';
import API, { getAuthorizedHeader } from 'utils/Api';
import UserProfile from '../../../../../domain/userProfile';
import { Multiselect } from 'multiselect-react-dropdown';
import { inject, observer } from 'mobx-react';
import { pageEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';
import { getResponseError } from 'utils/response';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ClubDatePicker from './datepicker';

const CreateClubDialog = inject("ProfileStore")(observer((props) => {

    pageEvent('create club dialog')

    const [show, setShow] = useState(true);

    const [languages, setLanguages] = useState([]);
    const [levels, setLevels] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [dropdownLevelItems, setDropdownLevelItems] = useState([]);
    const [selectedLevelValue, setSelectedLevelValue] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().getTime() + 86400000))

    let themeTitle = '';

    // Build the items for Multiselect view
    useEffect(() => {
        const items = levels.map((item, key) => {
            if (item.sort === -1) { // Set initial Value of Selector - 'Any Languages'
                setSelectedLevelValue([{
                    name: item.title,
                    id: item._id,
                    level: item
                }]);
            }
            return {
                name: item.title,
                id: item._id,
                level: item
            }
        });

        setDropdownLevelItems(items);
    }, [levels]);

    useEffect(() => {
        let ignore = false;

        API.get(
            'info/levslangs',
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (!ignore) {
                if (res.data.body.languages) {
                    setLanguages(res.data.body.languages);
                }

                if (res.data.body.levels) {
                    setLevels(res.data.body.levels);
                }
            }

        }).catch(error => {
            // FIXME: handle this error
        });

        return () => { ignore = true; }
    }, []);

    const buildBundler = ({ roomId, title }, { username, email }) => {
        localStorage.setItem(roomId, JSON.stringify({
            roomId: roomId,
            username: username,
            email: email,
            title: title,
            source: 'homepage'
        }));
    };

    const createClub = async () => {

        if (isDataRelevant(startDate)) {
            const profile = UserProfile.getUserProfile();

            let levelIds = [];

            if (selectedLevelValue.length > 0) {
                const sortedSelectedLevelValue = selectedLevelValue.sort((a, b) => {
                    if (a.level.sort > b.level.sort) {
                        return -1;
                    }

                    if (a.level.sort < b.level.sort) {
                        return 1;
                    }

                    return 0;
                })

                console.log(sortedSelectedLevelValue);
                levelIds = sortedSelectedLevelValue.map(item => {
                    return item.level._id;
                });
            } else {
                levelIds.push(levels.find(item => item.sort === -1));
            }

            const data = {
                author: profile._id,
                level: levelIds,
                language: selectedLanguage._id,
                title: themeTitle,
                date: Date.now(),
                roomId: '86800216fae4e5b283c47f69c0d3dd518d8ee836',
                startClubTime: startDate,
                linkClub: "https://www.linguaspeak.com/chat/86800216fae4e5b283c47f69c0d3dd518d8ee836"
            };

            try {
                const res = await API.post('speakingclubs/create', data, getAuthorizedHeader(props.ProfileStore.params.accessToken));
                if (res.data) {
                    setShow(false)
                    toast(`🎭 You successfully created the speaking club`)
                } else {
                    toast.error(`Something went wrong :(`)
                }
            } catch (error) {
                toast.error(`🤔 ${getResponseError(error)}`)
            }
        } else {
            toast.error(`Time cannot be in the past`)
        }
    }

    const goTo = ({ roomId }) => {
        function openNewTab(url) {
            const win = window.open(url, '_blank');
            if (win != null) {
                win.focus();
            }
        }

        openNewTab(`/chat/${roomId}`);
        handleClose();
    }

    useEffect(() => {
        const selectedLanguage = languages.filter((item) => {
            return item.sort === -1;
        });

        if (languages.length !== 0) {
            setSelectedLanguage(selectedLanguage[0]);
        }
    }, [languages]);

    const handleClose = () => {
        setShow(false);
        props.onModalClose();
    }

    const onThemeChange = (event) => {
        themeTitle = event.target.value;
    }

    const onStartDateChanged = (date) => {
        setStartDate(date)
    }

    const onSelect = (selectedList, selectedItem) => {
        let items = [];
        if (selectedItem.level.sort === -1) {
            items.push(selectedItem) // Remove all selected levels and add 'Any level'
        } else {
            items = selectedList.filter(item => {
                return item.level.sort !== -1
            });
        }
        setSelectedLevelValue(items)
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedLevelValue(selectedList)
    }

    const MultySelectorLevel = (levels) => {
        return <div className={styles.levelViewSelector}>
            <div className={styles.levelTitle}>Level</div>
            <Multiselect className={styles.levelDropDown}
                options={dropdownLevelItems} // Options to display in the dropdown
                selectedValues={selectedLevelValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                closeIcon="cancel"
            />
        </div>
    }

    const buildSelectArray = (items) => {
        let selectItems = [];
        if (items) {
            items.map(item => {
                if (item.sort !== -1) {
                    let label = item.title;
                    if (item.flag) {
                        label = <><Emoji symbol={item.flag} /> {item.title}</>
                    }
                    selectItems.push({ value: item.title, label: label })
                }
            })
        }
        return selectItems;
    }

    const body = () => {
        if (languages.length !== 0 && levels.length !== 0) {
            const langSelectClick = (lang) => {
                setSelectedLanguage(lang);
            }

            const languagesItems = buildSelectArray(languages);
            return <div className={styles.body}>

                <div className={styles.firstLine}>
                    <div className={styles.langView}>
                        <div className={styles.langTitle}>Language</div>
                        <div className={styles.languagesSelector}>
                            <Select
                                value={languagesItems.filter(item => item.value === selectedLanguage.title)}
                                className={styles.language}
                                options={languagesItems}
                                onChange={item => {
                                    const language = languages.find(lang => lang.title === item.value)
                                    langSelectClick(language);
                                }}
                                placeholder='Select Language' />
                        </div>
                    </div>
                </div>

                {MultySelectorLevel(levels)}

                <ClubDatePicker onSelect={onStartDateChanged} />

                <div className={styles.themeTitle}>Theme</div>
                <InputGroup className="mb-3" >
                    <FormControl
                        id="inputTheme"
                        placeholder="e.g Language learning"
                        aria-label="Theme"
                        aria-describedby="basic-addon1"
                        onChange={onThemeChange}
                    />
                </InputGroup>

            </div>;
        } else {
            return <div className={styles.bodyLoading}>
                <Spinner
                    className={styles.spinner}
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }}><Emoji symbol='🔨' /> Create Speaking club</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {body()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={createClub}>Create</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}));

export default withRouter(CreateClubDialog);

CreateClubDialog.propTypes = {
    optionalArray: PropTypes.array
}

function isDataRelevant(date) {
    const currentDate = new Date()
    if (date > currentDate) {
        return true
    }
    return false
}