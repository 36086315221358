import React from 'react';
import styles from './styles.module.scss';
import { inject, observer } from 'mobx-react';

const Levels = inject("ProfileStore")(observer((props) => {
    const { levelsThread, threadLanguage } = props;

    const profile = props.ProfileStore.params.profile;

    // Выделение уровня языка который соввподает с уровенем в профиле
    // const getCurrentLanguage = (profile) => {
    //     let language;
    //     let level;

    //     debugger
    //     if (profile && profile.languages) {
    //         for (let i = 0; i < profile.languages.length; i++) {
    //             if (profile.languages[0].language === threadLanguage._id) {
    //                 language = profile.languages[0].language;
    //                 level = profile.languages[0].level;
    //                 break;
    //             }
    //         }
    //     }

    //     return {
    //         language: language,
    //         level: level
    //     }
    // }

    const LevelTitles = (levelsThread, existedLanguage) => {
        if (levelsThread) {
            return levelsThread.map((item, index) => {
                let title = item.title;

                if (index < levelsThread.length - 1) {
                    title += ', '
                }

                // const condition = existedLanguage.language === undefined
                //     || existedLanguage.level === undefined
                //     || existedLanguage.level !== item._id;

                // if (condition) {
                return <span className={styles.levelTitle} key={index}>{title}</span>
                // } else {
                //     return <span className={styles.levelTitle2} key={index}>{title}</span>
                // }
            })
        }
    }

    return (
        <div className={styles.body}>
            <div className={styles.recommendTitle}>Recommended Levels:</div>
            {LevelTitles(levelsThread)}
        </div>
    );
}));

export default Levels;   