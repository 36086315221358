


export function getResponseError(error) {
    let errorsMap = new Map();
    try {
        if (error.response.data.data.message) {
            objectToMap(errorsMap, error.response.data.data.message)
        } else if (error.response.data.data.errors.length > 0) {
            for (let i = 0; i < error.response.data.data.errors.length; i++) {
                objectToMap(errorsMap, error.response.data.data.errors[i]);
            }
        } else {
            objectToMap(errorsMap, { general: error.message })
        }
    } catch (e) {
        objectToMap(errorsMap, { general: 'Something went wrong =(' })
    }

    return errorsMap;
}

function objectToMap(map, obj) {
    const key = Object.keys(obj)[0];
    const value = Object.values(obj)[0];
    map.set(key, value);
}