import React, { useState, useEffect } from "react";
import s from './styles.module.scss';
import { withRouter } from 'react-router';
import User from '../../../components/user';
import { inject, observer } from 'mobx-react';
import JoinButton from '../../../components/threads/button'
import API from 'utils/Api';
import Thread from '../../../components/threads/thread';
import WrongLink from './wrongLink'

const JoinToChat = inject("ProfileStore")(observer((props) => {
    const [isLoading, setLoading] = useState(false)
    const [thread, setThread] = useState();
    const [isBadUrlError, setBadUrlError] = useState(false);

    const profile = props.ProfileStore.params.profile;

    const getJoinTitleName = (profile) => {
        if (profile) {
            return `Join as the ${profile.username}`
        }
    }

    useEffect(() => {
        GetThread();
    }, []);

    const GetThread = (event) => {
        setLoading(true);
        API.get(
            `thread/detail/${props.roomId}`
        ).then(res => {
            setLoading(false);
            if (res.data && res.data.thread) {
                setThread(res.data.thread)
            } else {
                setBadUrlError(true)
            }
        }).catch(error => {
            setLoading(false);
        });
    };

    const joinEvent = () => {
        const { history } = props;
        if (history) history.push(`/`);
    }

    return (
        <div className={s.body}>
            {!isBadUrlError ?
                <>
                    <div className={s.titleJoin}>{getJoinTitleName(profile)}</div>
                    {thread && <div className={s.join}>
                        <JoinButton thread={thread} joinEvent={joinEvent} />
                    </div>}
                    <div className={s.user}>
                        <User user={profile} />
                    </div>
                    <div className={s.titleJoin}>To the thread</div>

                    <div className={s.thread}>
                        {thread && <Thread thread={thread} joinEvent={joinEvent} />}
                    </div>
                </>
                : <WrongLink />
            }
        </div >
    )
}));

export default withRouter(JoinToChat);
