import React from "react";
import styles from "./styles.module.scss";
import Emoji from 'utils/emoji';

const Languages = ({ languages }) => {

    const sortLanguages = (languages) => {
        const sorted = languages.sort((a, b) => {
            if (a.level.sort > b.level.sort) {
                return 1;
            }
            if (a.level.sort < b.level.sort) {
                return -1;
            }
            return 0;
        });
        languages = sorted;
    }

    sortLanguages(languages);

    return <div className={styles.langView}>
        {languages.map((item, key) => {
            return <div key={key} className={styles.lang} >
                <Emoji symbol={item.language.flag}/> {item.language.title} <span className={styles.level}>{item.level.title}</span>
            </div>
        })}
    </div >
}

export default Languages;