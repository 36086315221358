import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import Members from '../../components/threads/members/unlimited'
import { withRouter } from 'react-router-dom'
import SubscribeButton from '../../components/clubs/subscribeButton'
import Levels from '../../components/views/levels'
import Emoji from 'utils/emoji'
import { Spinner } from "react-bootstrap"
import API from 'utils/Api';

const ClubPage = (props) => {
    const [club, setClub] = useState();
    const [isLoading, setLoading] = useState(true);

    const clubId = props.match.params.clubId

    useEffect(() => {
        fetchClub()
    }, []);

    const fetchClub = () => {
        setLoading(true)
        API.get(
            `speakingclubs/detail/${clubId}`
        ).then(res => {
            setLoading(false)
            if (res.data && res.data.club) {
                setClub(res.data.club)
            }
        }).catch(error => {
            setLoading(false);
        })
    }

    const StartDate = (startClubTime) => {
        const d = new Date(startClubTime)

        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]

        const monthName = months[d.getMonth()]
        const year = d.getFullYear()
        const date = d.getDate()
        const hours = d.getHours()
        const minutes = d.getMinutes()

        const timeFormatted = `${hours}:${minutes}`
        const dateFormatted = `${date} ${monthName} ${year}`

        if (isClubStarted(startClubTime)) {
            return <div className={styles.date}>
                <Emoji symbol='🎉' />
                <span>&#160;</span>
                <div className={styles.title}>The club has started!</div>
            </div>
        } else {
            return <div className={styles.date}>
                <Emoji symbol='🏁' />
                <span>&#160;</span>
                <div className={styles.title}>Club will start</div>
                <div className={styles.titleTime}>at {timeFormatted}</div>
                <div className={styles.titleDate}>{dateFormatted}</div>
            </div>
        }
    }

    const isClubStarted = (startClubTime) => {
        return new Date(startClubTime) < new Date()
    }

    const onClickSeeMoreInfo = () => {

    }

    const Button = () => {
        if (isClubStarted()) {
            return <div className={styles.titleClubStarted}>The club has started, You can join!</div>
        } else {
            return <SubscribeButton club={club} />
        }
    }

    const SpinnerComponent = () => {
        return <Spinner className={styles.loader}
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
        />
    }

    const RegisteredParticipantsTitle = (members) => {
        let membersCount = 0
        if (members) {
            membersCount = members.length
        }
        return <div className={styles.titleClubStarted}><Emoji symbol='✔️' /> {membersCount} registered participants!</div>
    }

    const Host = () => {
        const { author, members } = club;

        let user = members.find(item => author._id == item._id)

        if (user) {
            return <div className={styles.hostBlock}>
                <div className={styles.title}><Emoji symbol='🦁' />The speaking club was scheduled by:</div>
                <Members members={[user]} onClickSeeMoreInfo={onClickSeeMoreInfo} />
            </div>
        } else return <></>
    }

    const Body = () => {
        if (club) {
            const { title, language, level, members, startClubTime, author } = club
            return <>
                <div className={styles.header}>
                    <div>
                        <div className={styles.title}><Emoji symbol={language.flag} /> {language.title}</div>

                        <Levels
                            levelsThread={level}
                            threadLanguage={language}
                        />

                        {title && <div className={styles.themeDiv}><span className={styles.themeTitle}><Emoji symbol='💬' /> Theme: </span>"{title}"</div>}
                    </div>
                    {StartDate(startClubTime)}
                </div>

                <Host />

                {RegisteredParticipantsTitle(members)}
                <Members members={members} onClickSeeMoreInfo={onClickSeeMoreInfo} author={author} />

                <div className={styles.footer}>
                    {Button()}
                </div>
            </>
        } else {
            return <div>Error</div>
        }
    }

    return (
        <div className={styles.body} >
            {isLoading ? SpinnerComponent() : Body()}
        </div>
    );
}

export default withRouter(ClubPage)