import React, { useEffect, useState } from "react";
import API, { getAuthorizedHeader } from 'utils/Api';
import styles from "./styles.module.scss";
import LanguagesSelector from '../languagesSelector/selector';
import Form from 'react-bootstrap/Form'
import UserProfile from '../../../../domain/userProfile';
import { withRouter } from 'react-router-dom';
import ImagePicker from './imagePicker';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { getRefreshedUrl } from 'utils/user'
import { pageEvent } from 'utils/analitycs'
import { Spinner } from 'react-bootstrap'
import buildLanguageAbilityTitle from './builderLanguageTitle'

const UpdateUser = inject("ProfileStore")(observer((props) => {
    pageEvent('update page')

    let profile = UserProfile.getUserProfile();

    const [languages, setLanguages] = useState([]);
    const [levels, setLevels] = useState([]);
    const [bioText, setBioText] = useState(profile.bio);
    const [showDialog, setShowDialog] = useState(false);
    const [imagePreview, setImagePreview] = useState();
    const [file, setFile] = useState();
    const [isLoadingState, setLoadingState] = useState(false);


    const defaultValue = profile.languages && profile.languages !== undefined ? profile.languages : [];
    const [selectLanguages, setSelectLanguages] = useState(defaultValue);

    let sizeImage = 70;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        API.get(
            'info/levslangs',
        ).then(res => {
            if (res.data.body) {
                setLanguages(res.data.body.languages);
                setLevels(res.data.body.levels);
            }
        }).catch(error => {
            alert(error);
        });
    }

    const putCompleation = ({ _id, username }, selectedItems, bio) => {
        setLoadingState(true);

        var bodyFormData = new FormData();
        bodyFormData.set('userId', _id);
        bodyFormData.append('selectedItems', JSON.stringify(selectedItems));
        if (bio === undefined) bio = ''
        bodyFormData.set('bio', bio);
        bodyFormData.set('isRegisterCompleted', true);
        bodyFormData.append('picture', file);
        bodyFormData.append('languageAbilityTitle', buildLanguageAbilityTitle(languages, levels, selectedItems));

        API.put(
            `user/${username}/update`,
            bodyFormData,
            getAuthorizedHeader(props.ProfileStore.params.accessToken)
        ).then(res => {
            setLoadingState(false);

            if (res.data.user) {
                toast('🎭 You successfully updated profile');
                UserProfile.setUserProfile(res.data.user);
                routeMain();
            } else {
                toast.error('😓 Oops, something gets wrong')
            }
        }).catch(error => {
            setLoadingState(false);

            let titleError = error;

            if (error.response && error.response.data && error.response.data.message) {
                titleError = error.response.data.message
            }
            toast.error(`😓 ${titleError}`)
        });
    }

    const routeMain = () => {
        const { history } = props;
        if (history) history.push(`/feed`);
    }

    const ProfileHeader = (imagePreview, { image, username, firstName, lastName }) => {

        const onClick = () => {
            setShowDialog(true);
        }

        const avatarCurrent = image && image.url ? getRefreshedUrl(image.url) : '/anonim.png';
        const avatar = imagePreview ? imagePreview : avatarCurrent;

        return (<>
            <div className={styles.profileBody}>
                <img
                    className={styles.image}
                    src={avatar}
                    width={sizeImage}
                    height={sizeImage}
                    alt='ava'
                    onClick={onClick} />

                <div className={styles.profileTitles}>
                    <div className={styles.userName}>{username}</div>
                    <div className={styles.userName}>{firstName} {lastName}</div>
                </div>

            </div>
            <div className={styles.updateAvataTitle} onClick={onClick} >Change photo</div>
        </>)
    }

    const Bio = (text) => {
        return (
            <Form.Group controlId="exampleForm.ControlTextarea1" className={styles.bio}>
                <Form.Label>Bio</Form.Label>
                <Form.Control
                    value={bioText}
                    as="textarea"
                    rows="3"
                    placeholder="Who are you?..."
                    onChange={(event) => setBioText(event.target.value)}
                />
            </Form.Group>
        );
    }

    const validateData = () => {
        return true;
    }

    const onCompleate = () => {
        const selectedItems = buildSelecteLanguages();
        if (validateData()) {
            const items = removeLanguagesDuplicates(selectedItems);
            putCompleation(profile, items, bioText);
        }
    }

    const onSelectedItemsListener = (items) => {
        setSelectLanguages(items)
    }

    const removeLanguagesDuplicates = (items) => {
        var map = {};
        const filtered = items.filter(item => {
            return map.hasOwnProperty(item.language) ? false : (map[item.language] = true);
        });
        return filtered;
    }

    // Collect only filled language and level inputs
    const buildSelecteLanguages = () => {
        let items = []
        if (selectLanguages !== null && selectLanguages !== undefined) {
            items = selectLanguages.filter((item) => {
                return typeof item.language === 'string' && typeof item.level === 'string';
            });
        }
        return items;
    }

    const onModalClose = () => {
        setShowDialog(false);
    }

    const seletListener = image => {
        debugger
        setImagePreview(image);
    }

    const fileListener = file => {
        debugger
        setFile(file);
    }

    const UpdateButton = () => {
        if (!isLoadingState) {
            return <div onClick={onCompleate} className={styles.saveButton}>Save</div>
        } else {
            return <div className={styles.loader} disabled >
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        }
    }

    return (
        <div className={styles.body}>
            {ProfileHeader(imagePreview, profile)}
            {levels && languages && (
                <LanguagesSelector
                    className={styles.languageSelector}
                    levels={levels}
                    languages={languages}
                    userLanguages={selectLanguages}
                    onSelectedItemsListener={onSelectedItemsListener}
                />
            )}

            {Bio()}

            <UpdateButton />
            {showDialog && <ImagePicker onModalClose={onModalClose} show={showDialog} seletListener={seletListener} fileListener={fileListener} />}
        </div>
    );
}));

export default withRouter(UpdateUser);