import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { Modal, Button } from 'react-bootstrap'
import API, { getAuthorizedHeader } from 'utils/Api';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { modalEvent } from 'utils/analitycs'
import Emoji from 'utils/emoji';

const RateUser = inject("ProfileStore")(observer((props) => {
    
    const { user, raterId } = props;

    modalEvent('Rate user')

    const startDefault = {
        speakingQuality: {
            title: "Speaking quality",
            rating: 0,
            weight: 80
        },
        connectionQuality: {
            title: "Сonnection quality",
            rating: 0,
            weight: 20
        },
    };

    const [show, setShow] = useState(true);
    const [titleError, setTitleError] = useState();
    const [stars, setStars] = useState(startDefault);
    const [overallRating, setOverallRating] = useState(0);

    useEffect(() => {
        setOverallRating(getOverallRating(stars.connectionQuality, stars.speakingQuality))
    }, [stars]);

    function getOverallRating(connectionQuality, speakingQuality) {
        if (
            connectionQuality
            && speakingQuality
            && connectionQuality.rating !== 0
            && speakingQuality.rating !== 0
        ) {

            function getRating({ weight, rating }) {
                return rating * weight / 100;
            }

            let ratingSpeaking = getRating(speakingQuality);
            let ratingСonnection = getRating(connectionQuality);

            return Math.round((ratingSpeaking + ratingСonnection) * 10) / 10;
        }
        return 0;
    }

    const rateUser = async () => {
        if (validateRatigns()) {

            const body = {
                "user": user._id,
                "rater": raterId,
                "speakingQuality": stars.speakingQuality.rating,
                "connectionQuality": stars.connectionQuality.rating
            };

            API.post(
                'user/rating',
                body,
                getAuthorizedHeader(props.ProfileStore.params.accessToken)
            ).then(res => {
                setShow(false);
                props.onModalClose(user);
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
                    toast.error(error.response.data.data.message);
                } else {
                    toast.error(error.message);
                }
            });
        }
    }

    const handleClose = () => {
        setShow(false);
        props.onModalClose();
    }

    const changeRating = (newRating, name) => {
        let refreshedStart = { ...stars };
        switch (name) {
            case startDefault.connectionQuality.title:
                refreshedStart.connectionQuality.rating = newRating;
                break;

            case startDefault.speakingQuality.title:
                refreshedStart.speakingQuality.rating = newRating;
                break;

            default:
        }
        setStars(refreshedStart);
    }

    const validateRatigns = () => {
        for (let [key, item] of Object.entries(stars)) {
            if (item.rating === 0) {
                // setTitleError('Rate all the skills');
                toast.error('😱 Rate all the skills');
                return false;
            }
        }
        setTitleError();
        return true;
    }

    const Body = (stars) => {
        let views = [];
        for (let [key, item] of Object.entries(stars)) {
            views.push(
                <div key={key} className={styles.starBlock}>
                    <span className={styles.title}>{item.title}</span>

                    <StarRatings
                        starRatedColor="#ff5a5f"
                        rating={item.rating}
                        changeRating={changeRating}
                        numberOfStars={5}
                        starDimension='30px'
                        name={item.title}
                    />
                </div>
            );
        }

        return <div className={styles.body}>
            <Button className={styles.complainTitle} onClick={props.openReportUserDialog}>Complain on user {user.username}</Button>

            <div className={styles.titleError}>{titleError}</div>
            {overallRating !== 0 &&
                <div className={styles.overallRating}>Overall rating - <span className={styles.overallRatingNumber}>{overallRating}</span></div>
            }
            {views}
        </div>;
    }

    const Header = () => {
        return <div className={styles.header}>
            <div className={styles.title}><Emoji symbol='⭐'/> Rate user - {user.username}</div>
        </div>
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }}>{Header()}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Body(stars)}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={rateUser}>Rate</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}));

export default RateUser;
