import React from "react"
import s from "./styles.module.scss"
import Threads from "../../components/threads"
import CreateButton from "../create/button/buttton"
import { pageEvent } from 'utils/analitycs'
import NavPanel from '../../components/navPanel'

const HomePage = () => {
    pageEvent('home page')

    return (
        <div className={s.body}>
            <NavPanel type="rooms" />
            <CreateButton />
            <Threads />
        </div>
    );
}

export default HomePage
