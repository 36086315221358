export const ARG_ACCESS_TOKEN = 'access_token'
export const ARG_AUTHORIZED = 'authorized_user'

const UserProfile = (function () {
  let user = {};
  let token = '';

  let getUserProfile = function () {
    return JSON.parse(localStorage.getItem(ARG_AUTHORIZED));
  };

  let getAccessToken = function () {
    return localStorage.getItem(ARG_ACCESS_TOKEN);
  };

  let setUserProfile = function (_user) {
    user = _user;
    localStorage.setItem(ARG_AUTHORIZED, JSON.stringify(user));
  };

  let setAccessToken = function (_token) {
    token = _token;
    localStorage.setItem(ARG_ACCESS_TOKEN, token);
  };

  let clearUserProfile = function () {
    localStorage.removeItem(ARG_AUTHORIZED);
    localStorage.removeItem(ARG_ACCESS_TOKEN);
  }

  return {
    getUserProfile: getUserProfile,
    setUserProfile: setUserProfile,
    clearUserProfile: clearUserProfile,
    getAccessToken: getAccessToken,
    setAccessToken: setAccessToken,
  }
})();

export default UserProfile;