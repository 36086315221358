import React from "react";
import s from "./level.module.scss";
import API from 'utils/Api';

class LevelsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: [],
        };
    }

    componentWillMount() {
        API.get('/info/levels')
            .then((response) => response.data.body)
            .then((body) => {
                if (body.levels) {
                    this.setState({
                        levels: body.levels,
                    });
                    console.log(this.state.levels);
                }
            });
    }

    componentDidUpdate() { }

    render() {
        let count = 9
        let views = [];
        for (let i = 0; i < this.state.levels.length; i++) {
            let number;
            if (this.state.levels[i].description === "") {
                number = this.state.levels[i].shortTitle;
            } else {
                number = this.state.levels[i].description;
            }
            let view = (
                <div className={s.AppText} key={this.state.levels[i]._id}>
                    <table >
                        <tbody>
                            <tr>
                                <td><h2>{count = count - 1}</h2></td>
                                <td><h2>{this.state.levels[i].title}</h2></td>
                                <td>{number}</td>
                                <td><h2>{this.state.levels[i].shortTitle}</h2></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
            if (this.state.levels[i].sort >= 0) {
                views.push(view);
            }
        }
        const reversed = views.reverse();
        return (
            <>
                <p className={s.title}>Your English Level
You can discover your level of English on a scale from 1 (Beginner) to 7 (Native). Check the table below to see which level you have, or take a 20 minute free Online English Level Test which will help you understand your English level with accuracy.</p>
                <table className={s.AppTable}>
                    <tbody>
                        <tr>

                            <td><h2 className={s.titleColumn}>Level</h2></td>
                            <td><h2 className={s.titleColumn}>Class Level</h2></td>
                            <td><h2 className={s.titleColumn}>Description</h2></td>
                            <td><h2 className={s.titleColumn}>CEFR Level*</h2></td>
                        </tr>
                    </tbody>
                </table>
                {reversed}
            </>
        );
    }
}

export default LevelsInfo;

