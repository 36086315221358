import React from "react"
import s from "./styles.module.scss"
import Clubs from "../../components/clubs"
import { pageEvent } from 'utils/analitycs'
import NavPanel from '../../components/navPanel'
import CreateClub from '../../components/clubs/create/button'

const SpeakingClubsPage = () => {
    pageEvent('speaking clubs page')

    return (
        <div className={s.body}>
            <NavPanel type="clubs" />
            <div className={s.header}>
                <div className={s.title}>Speaking club is a place where you can can practice speaking with natives in scheduled time!</div>

                <div className={s.itWorksTitle}>It works in 3 steps:</div>
                <ul>
                    <li>Just choose the club with time you can join.</li>
                    <li>Subscribe and get letter with reservation in calendar</li>
                    <li>Join club when it starts</li>
                </ul>
            </div>
            <CreateClub />
            <Clubs />
        </div>
    );
}

export default SpeakingClubsPage
