import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styles from "./styles.module.scss"
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import API, { getAuthorizedHeader } from 'utils/Api'
import { Spinner } from "react-bootstrap"
import { joinToThreadEvent } from 'utils/analitycs'

const SubscribeButton = inject("ProfileStore")(observer((props) => {
    const [isLoadingState, setLoadingState] = useState(false)
    const [isSubscribed, setSubscribed] = useState(false)

    const { club } = props
    const { members, numberSeats } = club

    const onClickSubscribe = () => {
        setLoadingState(true)

        const body = {
            clubId: club._id,
            userId: props.ProfileStore.params.profile._id,
            email: props.ProfileStore.params.profile.email
        }

        API.post(
            '/speakingclubs/subscribe',
            body,
            getAuthorizedHeader(props.ProfileStore.params.accessToken)
        ).then(res => {
            setLoadingState(false)
            if (res.data.success) {
                setSubscribed(true)
                if (res.data.alreadySubscribed) {
                    toast('🎭 You successfully were subscribed one more time on speaking club');

                } else {
                    toast('🎭 You successfully were subscribed on speaking club');
                }
            } else {
                toast.error('Something went wrong')
            }
        }).catch(error => {
            toast.error('Something went wrong')
            setLoadingState(false)
        });
    }

    const Join = () => {
        if (props.ProfileStore.params.profile && club && club.roomId) {
            if (!isLoadingState) {

                if (!isSubscribed) {
                    return <div
                        className={styles.activeButton}
                        onClick={(e) => {
                            e.stopPropagation()
                            joinToThreadEvent('click join club');
                            onClickSubscribe()
                        }}
                    >Subscribe</div>
                } else {
                    return <div className={styles.nonActiveButton}>Subscribed</div>
                }
            } else {
                return <div className={styles.activeButton} disabled >
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            }
        } else return <div className={styles.nonActiveButton}>You are not loged in</div>
    };

    const onClickConnect = () => {
        if (props.ProfileStore.params.profile) {
            if (!club && club.roomId) {
                toast.error('🤝 there is no room id', { autoClose: 2000 });
                // FIXME: handle Error
            }
        } else {
            joinToThreadEvent('not authorized');
            toast.warn('🤝 You need a login, in order to join a thread', { autoClose: 2000 });
        }
    }

    return (
        Join()
    )
}))

export default withRouter(SubscribeButton)